import { Event, EventHint } from '@sentry/react';
import { isRouteErrorResponse } from 'react-router-dom';

import PlotApi, { ApiError } from '@plot/plot-api';

// Do not report following errors to Sentry
const excludeStatusCodes = [401, 402, 403, 404];

export function beforeSend(
  event: Event,
  hint: EventHint
): Event | PromiseLike<Event | null> | null {
  const exception = hint.originalException;

  if (
    isRouteErrorResponse(exception) ||
    exception instanceof Response ||
    exception instanceof ApiError
  ) {
    if (excludeStatusCodes.includes(exception.status)) {
      return null;
    }
  }

  return event;
}

export default beforeSend;
