import { Suspense, lazy, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Button, Loader } from '@plot/ui';

import { useWorkspace } from '@/lib/contexts';

const PlotMap = lazy(() => import('../plot-map'));
const Drawers = lazy(() => import('../drawers'));

function checkPathnameEnding(str: string): boolean {
  const endings = [
    '/settings',
    '/members',
    '/subscription',
    '/invoices',
    '/profile',
  ];
  return endings.some((ending) => str.endsWith(ending));
}

export function Workspace() {
  const t = useTranslations('Global');
  const { loading, workspace, workspaces } = useWorkspace();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (checkPathnameEnding(pathname)) return;
    const noWorkspaces = workspaces?.length === 0;
    const isWorkspaceSuspended = workspace?.isSuspended;

    if (noWorkspaces) {
      navigate(`/workspaces/inactive`);
      return;
    }

    if (isWorkspaceSuspended) {
      navigate(`/workspaces/suspended`);
      return;
    }

    if (workspace) {
      if (!workspace.isSetupComplete && workspace.role === 'owner') {
        navigate(`/workspaces/${String(workspace.id)}/setup`);
        return;
      }
    }
  }, [pathname, workspace, workspaces]);

  function renderContent() {
    if (!workspace && !loading && workspaces?.length !== 0) {
      return (
        <div className="relative flex h-screen items-center justify-center p-8">
          <div className="text-center">
            <h2 className="mb-6 text-4xl">
              <div className="text-primary">404</div>
              {t('workspaceNotFound')}
            </h2>
            <div className="mb-8 text-md">
              {t.rich('notFoundMessage', {
                paragraph: (chunks) => (
                  <p className="mb-5 last:mb-0">{chunks}</p>
                ),
              })}
            </div>
            <Button as={Link} to={'/'} variant="neutral">
              {t('home')}
            </Button>
          </div>
        </div>
      );
    }

    return <PlotMap />;
  }

  return (
    <>
      <Suspense fallback={<Loader backdrop cover />}>
        {renderContent()}
      </Suspense>
      <Suspense fallback={null}>
        <Drawers active={!workspace?.isSuspended} />
      </Suspense>
    </>
  );
}

export default Workspace;
