import { useMemo } from 'react';
import { ArrowLeft } from '@phosphor-icons/react';
import { animated, useTransition } from '@react-spring/web';
import { Link, useLocation } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useProfile } from '@/lib/api';
import { useWorkspace } from '@/lib/contexts';
import { useSection } from '@/lib/hooks';

import { SidebarButton } from '@/components/sidebar';
// import { AccountMenu } from '@/components/sidebar/context-menu/_account-menu';
import { ManageMenu } from '@/components/sidebar/context-menu/manage-menu';

export type ContextMenuProps = {
  className?: string;
};

export function ContextMenu({ className }: ContextMenuProps) {
  const t = useTranslations('Sidebar.ContextMenu');
  const profile = useProfile();
  const location = useLocation();
  const section = useSection();
  const { workspace } = useWorkspace();

  const context = useMemo(() => {
    switch (section) {
      case '':
      case undefined:
      case 'workspaces':
        return 'root';

      case 'profile':
      case 'billing':
        return 'account';

      case 'settings':
      case 'members':
      case 'subscription':
      case 'invoices':
        return 'manage';

      default:
        return section;
    }
  }, [section]);

  const transition = useTransition(context, {
    initial: {
      opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    from: {
      opacity: 0,
      transform: (location.state as { back: boolean } | null)?.back
        ? 'translate3d(-100%, 0, 0)'
        : 'translate3d(100%, 0, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: (location.state as { back: boolean } | null)?.back
        ? 'translate3d(100%, 0, 0)'
        : 'translate3d(-100%, 0, 0)',
    },
  });

  if (!profile.data || profile.data?.isOnWaitingList) {
    return null;
  }

  function renderBack(item: string) {
    const noBack = ['root', 'create', 'setup', 'suspended'].includes(item);

    if (noBack) return null;
    if (!workspace) return null;

    const path = workspace.isSetupComplete
      ? `/workspaces/${String(workspace.id)}`
      : `/workspaces/${String(workspace.id)}/setup`;

    return (
      <div className="mb-8">
        <SidebarButton
          as={Link}
          to={path}
          relative="path"
          state={{ back: true }}
        >
          <ArrowLeft className="mr-2 h-5 w-5" /> {t('back')}
        </SidebarButton>
      </div>
    );
  }

  return transition((style, item) => (
    <animated.div
      key={item}
      className="w-full"
      style={{
        ...style,
        position: style.opacity.to((v) => (v !== 1 ? 'absolute' : 'static')),
      }}
    >
      {renderBack(item)}
      {item === 'manage' && workspace && <ManageMenu />}
      {/* {item === 'account' && <AccountMenu />} */}
    </animated.div>
  ));
}

export default ContextMenu;
