import { Dates } from '@/components/area-report/filter-control/component/date-range';
import { DateRangeType } from '@/components/area-report/filter-control/component/date-range/DateRangeSelector';
import { RangeType } from '@/components/area-report/filter-control/component/range';

//Map mode
export enum MapMode {
  BROWSING = 'browsing',
  REGULATORY_PLANS = 'regulatory_plans',
  MUNICIPAL_PLANS = 'municipal_plans',
  PRIMARY_AREA_HMAP = 'primary_area',
  USABLE_AREA_HMAP = 'usable_area',
  ROOMS_HMAP = 'rooms',
  BEDROOMS_HMAP = 'bedrooms',
  LISTING_PRICE_HMAP = 'listing_price',
  TRANSACTION_COSTS_HMAP = 'transaction_costs',
  JOIN_DEBT_HMAP = 'joint_debt',
  PRICE_HMAP = 'selling_price',
  DAYS_ON_MARKET_HMAP = 'days_on_market',
  SQM_PRICE_HMAP = 'sqm_price',
}

//Map filters
export enum MapFilterType {
  CHECKBOX = 'checkbox',
  CHECKBOX_FLAG = 'checkboxFlag',
  RANGE = 'range',
  DATE_PICKER = 'datePicker',
  DATE_SELECTOR = 'dateSelector',
  TOGGLE = 'toggle',
}

export enum NewProjectBasicFilters {
  NEW_PROJECT_ACTIVE = 'newProjectActive',
  NEW_PROJECT_ACTIVE_BETWEEN = 'newProjectActiveBetween',
  NEW_PROJECT_SALE_STATUS = 'newProjectSaleStatus',
  NEW_PROJECT_UNITS_LISTED_AT = 'newProjectUnitListedAt',
  NEW_PROJECT_UNITS_SOLD_AT = 'newProjectUnitSoldAt',
}

export enum NewProjectAdvancedFilters {
  NEW_PROJECT_UNIT_PROPERTY_TYPE = 'newProjectUnitPropertyType',
  NEW_PROJECT_UNIT_OWNERSHIP_TYPE = 'newProjectUnitOwnershipType',
  NEW_PROJECT_UNIT_SQM_PRICE = 'newProjectUnitSqmPrice',
  NEW_PROJECT_USABLE_SIZE = 'newProjectUsableSize',
  NEW_PROJECT_UNIT_ROOMS = 'newProjectUnitRooms',
  NEW_PROJECT_UNIT_SELLING_PRICE = 'newProjectUnitSellingPrice',
  NEW_PROJECT_UNIT_DAYS_ON_MARKET_MIN = 'newProjectUnitDaysOnMarketMin',
  NEW_PROJECT_CONSTRUCTION_YEAR = 'newProjectConstructionYear',
  NEW_PROJECT_FLOORS = 'newProjectFloors',
}

export const NewProjectFilter = {
  ...NewProjectBasicFilters,
  ...NewProjectAdvancedFilters,
};

export enum UsedUnitBasicFilters {
  USED_UNIT_ACTIVE = 'usedUnitActive',
  USED_UNIT_ACTIVE_BETWEEN = 'usedUnitActiveBetween',
  USED_UNIT_SALE_STATUS = 'usedUnitSaleStatus',
  USED_UNIT_SOLD_AT = 'usedUnitSoldAt',
  USED_UNIT_LISTED_AT = 'usedUnitListedAt',
}

export enum UsedUnitAdvancedFilters {
  USED_UNIT_PROPERTY_TYPE = 'usedUnitPropertyType',
  USED_UNIT_OWNERSHIP_TYPE = 'usedUnitOwnershipType',
  USED_UNIT_USE_TYPE = 'usedUnitUseType',
  USED_UNIT_SQM_PRICE = 'usedUnitSqmPrice',
  USED_UNIT_USABLE_AREA = 'usedUnitUsableArea',
  USED_UNIT_ROOMS = 'usedUnitRooms',
  USED_UNIT_SELLING_PRICE = 'usedUnitSellingPrice',
  USED_UNIT_DAYS_ON_MARKET = 'usedUnitDaysOnMarket',
  USED_UNIT_CONSTRUCTION_YEAR = 'usedUnitConstructionYear',
}

export enum NotIncludedFilter {
  NEW_PROJECT_ACTIVE = 'newProjectActive',
  NEW_PROJECT_ACTIVE_BETWEEN = 'newProjectActiveBetween',
  USED_UNIT_ACTIVE = 'usedUnitActive',
  USED_UNIT_ACTIVE_BETWEEN = 'usedUnitActiveBetween',
}

export enum FilterTypes {
  NewProjectBasicFilters = 'newProjectBasicFilters',
  NewProjectAdvancedFilters = 'newProjectAdvancedFilters',
  UsedUnitBasicFilters = 'usedUnitBasicFilters',
  UsedUnitAdvancedFilters = 'usedUnitAdvancedFilters',
}

interface MapFilterBase {
  id: string;
  label: string;
  apiParam: string | string[];
  tooltip?: string;
  disabledTooltip?: string;
  showLabelWithTooltip?: boolean;
}

interface MapFilterCheckbox extends MapFilterBase {
  type: MapFilterType.CHECKBOX;
  values: {
    id: string;
    label: string;
    checked: boolean;
  }[];
}
interface MapFilterCheckboxFlag extends MapFilterBase {
  type: MapFilterType.CHECKBOX_FLAG;
  values: {
    id: string;
    label: string;
    checked: boolean;
  }[];
}
interface MapFilterToggle extends MapFilterBase {
  type: MapFilterType.TOGGLE;
  values: boolean;
}

interface MapFilterRange extends MapFilterBase {
  type: MapFilterType.RANGE;
  values: RangeType;
  unit: string;
}

interface MapFilterDatePicker extends MapFilterBase {
  type: MapFilterType.DATE_PICKER;
  values: {
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
  };
  minDate?: Date;
}

interface MapFilterDateSelector extends MapFilterBase {
  type: MapFilterType.DATE_SELECTOR;
  values: {
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
  };
  dateRange: DateRangeType;
  minDate?: Date;
}

export type MapFilter =
  | MapFilterCheckbox
  | MapFilterCheckboxFlag
  | MapFilterRange
  | MapFilterDatePicker
  | MapFilterDateSelector
  | MapFilterToggle;
export interface IMapFilters {
  [key: string]: MapFilter;
}
export type MapControlType = 'mapMode' | 'mapFilters' | 'none';

//Map state
export interface IMapState {
  mapControl: MapControlType;
  mapFilters: IMapFilters;
  mapMode: MapMode;
  setMapControl: (control: MapControlType) => void;
  setMapMode: (mode: MapMode) => void;
  onFilterChange?: (
    filterId: string,
    value: string[] | RangeType | [string, string] | boolean | string,
    mapFilters: IMapFilters
  ) => void;
  setOnFilterChange: (
    onFilterChange?: (
      filterId: string,
      value: string[] | RangeType | [string, string] | boolean | string,
      mapFilters: IMapFilters
    ) => void
  ) => void;
  updateToggleMapFilter: (filterId: string, value: boolean) => void;
  updateCheckboxMapFilter: (
    filterId: string,
    valueId: string,
    checked: boolean
  ) => void;
  updateRangeMapFilter: (filterId: string, value: RangeType) => void;
  updateDateRangeMapFilter: (filterId: string, dates: Dates) => void;
  resetFilter: (filterId: string) => void;
  resetFilters: () => void;
}
