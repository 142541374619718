import { createTrackerProvider } from '@/lib/analytics/contexts/base';
import {
  AreaReportTracker,
  AreaReportTrackerBoundData,
} from '@/lib/analytics/trackers';
import { useProfile, useReport, useWorkspace } from '@/lib/contexts';
import { useMapStore } from '@/lib/store';

export const {
  TrackerProvider: AreaReportTrackerProvider,
  useTracker: useAreaReportTracker,
  useTrackerOnce: useAreaReportTrackerOnce,
} = createTrackerProvider<AreaReportTracker, AreaReportTrackerBoundData>(
  AreaReportTracker,
  () => ({
    boundData: {
      user: useProfile(),
      workspace: useWorkspace().workspace,
      mapModeName: useMapStore((state) => state).mapMode,
      areaReport: useReport(),
    },
    cacheKeys: [
      useProfile()?.id,
      useWorkspace().workspace?.id,
      useMapStore((state) => state).mapMode,
      useReport()?.id,
    ],
  })
);
