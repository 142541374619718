import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Button, Logo } from '@plot/ui';

export const Error = () => {
  const t = useTranslations('Global');
  const error = useRouteError();

  const errorStatus =
    isRouteErrorResponse(error) || error instanceof Response
      ? error.status
      : t('error');

  const errorTitle =
    isRouteErrorResponse(error) || error instanceof Response
      ? error.statusText
      : t('errorTitle');

  const errorMessage =
    isRouteErrorResponse(error) || error instanceof Response ? (
      isRouteErrorResponse(error) ? (
        <p>{error.error?.message}</p>
      ) : (
        error.statusText
      )
    ) : (
      t.rich('errorMessage', {
        paragraph: (chunks) => <p className="mb-5 last:mb-0">{chunks}</p>,
      })
    );

  return (
    <main id="main" className="min-h-screen sm:grid sm:grid-cols-2">
      <div className="items dark flex h-52 flex-col items-center justify-center bg-neutral-70 dark:bg-neutral-0 sm:h-full">
        <Logo className="block h-auto w-44 text-neutral-70 dark:text-neutral-0" />
      </div>

      <div className="relative flex items-center justify-center p-8">
        <div className="text-center">
          <h2 className="mb-6 text-3xl">
            <div className="text-primary">{errorStatus}</div>
            {errorTitle}
          </h2>
          <div className="mb-8 text-md">{errorMessage}</div>
          <Button as={Link} to={'/'} variant="neutral">
            {t('home')}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default Error;
