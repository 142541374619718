import * as amplitude from '@amplitude/analytics-browser';

/***********************************************************************************************************************
 *
 * Wrap analytics provider SDK in our interface to:
 * - build high level interface on top of it
 * - ensure we can easily swap provider if needed
 *
 **********************************************************************************************************************/

let userId: string | undefined = undefined;
const userEmail: string | undefined = undefined;

function getApiKey() {
  return import.meta.env.VITE_AMPLITUDE_API_KEY || undefined;
}

function getCustomServerUrl() {
  return import.meta.env.VITE_AMPLITUDE_SERVER_URL || undefined;
}

function getUserId() {
  return userId;
}

export function init() {
  const apiKey = getApiKey();
  if (!apiKey) {
    return;
  }

  let serverOptions;
  const serverUrl = getCustomServerUrl();
  if (serverUrl) {
    serverOptions = {
      serverUrl,
    };
  } else {
    serverOptions = {
      serverZone: amplitude.Types.ServerZone.EU,
    };
  }

  amplitude.init(apiKey, undefined, {
    ...serverOptions,
    defaultTracking: {
      pageViews: {
        trackHistoryChanges: 'pathOnly',
        eventType: 'PageViewed',
      },
    },
  });
  console.debug('Analytics:core:init');
}

export function setUser(
  newUserId: string | undefined,
  newUserEmail: string | undefined = undefined,
  trackingAudience: string | null | undefined = undefined
) {
  console.debug('Analytics:core:setUser');
  if (!getApiKey()) {
    return;
  }

  const optOut = trackingAudience === 'disable';
  if (userId == newUserId) {
    return;
  }

  console.debug(`Analytics:core:setUser:id=${String(newUserId)}`);
  console.debug(`Analytics:core:setUser:optOut=${String(optOut)}`);
  userId = newUserId;
  amplitude.setUserId(userId);

  // Opt out will not be changed without page refresh. We do not need this feature.
  amplitude.setOptOut(optOut);
}

export function track(eventName: string, eventProperties: object = {}) {
  if (!getApiKey()) {
    return;
  }

  amplitude.track(eventName, eventProperties);
  console.debug(`Analytics:core:track:${eventName}`, eventProperties);
}
