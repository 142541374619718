import { createTrackerProvider } from '@/lib/analytics/contexts/base';
import {
  WorkspaceTracker,
  WorkspaceTrackerBoundData,
} from '@/lib/analytics/trackers';
import { useApp, useProfile, useWorkspace } from '@/lib/contexts';
import { useMapStore } from '@/lib/store';

export const {
  TrackerProvider: WorkspaceTrackerProvider,
  useTracker: useWorkspaceTracker,
  useTrackerOnce: useWorkspaceTrackerOnce,
} = createTrackerProvider<WorkspaceTracker, WorkspaceTrackerBoundData>(
  WorkspaceTracker,
  () => ({
    boundData: {
      mapModeName: useMapStore((state) => state).mapMode,
      workspace: useWorkspace().workspace,
      user: useProfile(),
    },
    cacheKeys: [
      useProfile()?.id,
      useWorkspace().workspace?.id,
      useMapStore((state) => state).mapMode,
    ],
  })
);
