//This file reads the filter values from the store and prepares the query string for the map filters to be used for the endpoints. It also counts the number of filters applied and returns the count for each filter type. This is used in the map filter component to show the number of filters applied for each filter type.

import { useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';

import { initialMapFilters, useMapStore } from '@/lib/store';
import {
  FilterTypes,
  IMapFilters,
  MapFilterType,
  NewProjectAdvancedFilters,
  NewProjectBasicFilters,
  NotIncludedFilter,
  UsedUnitAdvancedFilters,
  UsedUnitBasicFilters,
} from '@/lib/types';

const filterMappings = {
  [FilterTypes.NewProjectBasicFilters]: NewProjectBasicFilters,
  [FilterTypes.NewProjectAdvancedFilters]: NewProjectAdvancedFilters,
  [FilterTypes.UsedUnitBasicFilters]: UsedUnitBasicFilters,
  [FilterTypes.UsedUnitAdvancedFilters]: UsedUnitAdvancedFilters,
};

const getFilterType = (filterId: string): FilterTypes | undefined => {
  return Object.entries(filterMappings).find(([_, filterEnum]) =>
    Object.values(filterEnum).includes(filterId)
  )?.[0] as FilterTypes;
};

const isNotIncludedFilter = (value: string) => {
  return Object.values(NotIncludedFilter).includes(value as NotIncludedFilter);
};

const prepareMapFiltersQueryString = (mapFilters: IMapFilters) => {
  let newProjectQs = '';
  let usedUnitQs = '';

  let newProjectBasicFilterCount = 0;
  let newProjectAdvancedFilterCount = 0;
  let usedUnitBasicFilterCount = 0;
  let usedUnitAdvancedFilterCount = 0;

  for (const filter of Object.values(mapFilters)) {
    let isNewProjectBasicFilterApplied = false;
    let isNewProjectAdvancedFilterApplied = false;
    let isUsedUnitBasicFilterApplied = false;
    let isUsedUnitAdvancedFilterApplied = false;
    const filterType = getFilterType(filter.id);

    switch (filter.type) {
      case MapFilterType.CHECKBOX:
        filter.values.forEach((value) => {
          if (value.checked) {
            if (filterType === FilterTypes.NewProjectBasicFilters) {
              newProjectQs += `&${filter.apiParam as string}=${value.id}`;
              isNewProjectBasicFilterApplied = true;
            } else if (filterType === FilterTypes.NewProjectAdvancedFilters) {
              newProjectQs += `&${filter.apiParam as string}=${value.id}`;
              isNewProjectAdvancedFilterApplied = true;
            } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
              usedUnitQs += `&${filter.apiParam as string}=${value.id}`;
              isUsedUnitBasicFilterApplied = true;
            } else if (filterType === FilterTypes.UsedUnitAdvancedFilters) {
              usedUnitQs += `&${filter.apiParam as string}=${value.id}`;
              isUsedUnitAdvancedFilterApplied = true;
            }
          }
        });
        break;
      case MapFilterType.CHECKBOX_FLAG:
        filter.values.forEach((value, index) => {
          if (value.checked) {
            const apiParam = filter.apiParam[index];
            if (filterType === FilterTypes.NewProjectBasicFilters) {
              newProjectQs += `&${apiParam}=false`;
              isNewProjectBasicFilterApplied = true;
            } else if (filterType === FilterTypes.NewProjectAdvancedFilters) {
              newProjectQs += `&${apiParam}=false`;
              isNewProjectAdvancedFilterApplied = true;
            } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
              usedUnitQs += `&${apiParam}=false`;
              isUsedUnitBasicFilterApplied = true;
            } else if (filterType === FilterTypes.UsedUnitAdvancedFilters) {
              usedUnitQs += `&${apiParam}=false`;
              isUsedUnitAdvancedFilterApplied = true;
            }
          }
        });
        break;

      case MapFilterType.RANGE:
        if (filter.values[0] != null) {
          if (filterType === FilterTypes.NewProjectBasicFilters) {
            newProjectQs += `&${filter.apiParam[0]}=${filter.values[0]}`;
            isNewProjectBasicFilterApplied = true;
          } else if (filterType === FilterTypes.NewProjectAdvancedFilters) {
            newProjectQs += `&${filter.apiParam[0]}=${filter.values[0]}`;
            isNewProjectAdvancedFilterApplied = true;
          } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
            usedUnitQs += `&${filter.apiParam[0]}=${filter.values[0]}`;
            isUsedUnitBasicFilterApplied = true;
          } else if (filterType === FilterTypes.UsedUnitAdvancedFilters) {
            usedUnitQs += `&${filter.apiParam[0]}=${filter.values[0]}`;
            isUsedUnitAdvancedFilterApplied = true;
          }
        }
        if (filter.values[1] != null) {
          if (filterType === FilterTypes.NewProjectBasicFilters) {
            newProjectQs += `&${filter.apiParam[1]}=${filter.values[1]}`;
            isNewProjectBasicFilterApplied = true;
          } else if (filterType === FilterTypes.NewProjectAdvancedFilters) {
            newProjectQs += `&${filter.apiParam[1]}=${filter.values[1]}`;
            isNewProjectAdvancedFilterApplied = true;
          } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
            usedUnitQs += `&${filter.apiParam[1]}=${filter.values[1]}`;
            isUsedUnitBasicFilterApplied = true;
          } else if (filterType === FilterTypes.UsedUnitAdvancedFilters) {
            usedUnitQs += `&${filter.apiParam[1]}=${filter.values[1]}`;
            isUsedUnitAdvancedFilterApplied = true;
          }
        }
        break;

      case MapFilterType.DATE_PICKER:
      case MapFilterType.DATE_SELECTOR:
        if (filter.values.minDate) {
          if (filterType === FilterTypes.NewProjectBasicFilters) {
            newProjectQs += `&${filter.apiParam[1]}=${format(
              filter.values.minDate,
              'yyyy-MM-dd'
            )}`;
            isNewProjectBasicFilterApplied = true;
          } else if (filterType === FilterTypes.NewProjectAdvancedFilters) {
            newProjectQs += `&${filter.apiParam[1]}=${format(
              filter.values.minDate,
              'yyyy-MM-dd'
            )}`;
            isNewProjectAdvancedFilterApplied = true;
          } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
            usedUnitQs += `&${filter.apiParam[1]}=${format(
              filter.values.minDate,
              'yyyy-MM-dd'
            )}`;
            isUsedUnitBasicFilterApplied = true;
          } else if (filterType === FilterTypes.UsedUnitAdvancedFilters) {
            usedUnitQs += `&${filter.apiParam[1]}=${format(
              filter.values.minDate,
              'yyyy-MM-dd'
            )}`;
            isUsedUnitAdvancedFilterApplied = true;
          }
        }
        if (filter.values.maxDate) {
          if (filterType === FilterTypes.NewProjectBasicFilters) {
            newProjectQs += `&${filter.apiParam[0]}=${format(
              filter.values.maxDate,
              'yyyy-MM-dd'
            )}`;
            isNewProjectBasicFilterApplied = true;
          } else if (filterType === FilterTypes.NewProjectAdvancedFilters) {
            newProjectQs += `&${filter.apiParam[0]}=${format(
              filter.values.maxDate,
              'yyyy-MM-dd'
            )}`;
            isNewProjectAdvancedFilterApplied = true;
          } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
            usedUnitQs += `&${filter.apiParam[0]}=${format(
              filter.values.maxDate,
              'yyyy-MM-dd'
            )}`;
            isUsedUnitBasicFilterApplied = true;
          } else if (filterType === FilterTypes.UsedUnitAdvancedFilters) {
            usedUnitQs += `&${filter.apiParam[0]}=${format(
              filter.values.maxDate,
              'yyyy-MM-dd'
            )}`;
            isUsedUnitAdvancedFilterApplied = true;
          }
        }

        break;
    }

    if (isNewProjectBasicFilterApplied && !isNotIncludedFilter(filter.id))
      newProjectBasicFilterCount++;
    if (isNewProjectAdvancedFilterApplied && !isNotIncludedFilter(filter.id))
      newProjectAdvancedFilterCount++;
    if (isUsedUnitBasicFilterApplied && !isNotIncludedFilter(filter.id))
      usedUnitBasicFilterCount++;
    if (isUsedUnitAdvancedFilterApplied && !isNotIncludedFilter(filter.id))
      usedUnitAdvancedFilterCount++;
  }

  return {
    newProjectQs,
    usedUnitQs,
    newProjectFilterCount:
      newProjectBasicFilterCount + newProjectAdvancedFilterCount,
    newProjectBasicFilterCount,
    newProjectAdvancedFilterCount,
    usedUnitFilterCount: usedUnitBasicFilterCount + usedUnitAdvancedFilterCount,
    usedUnitBasicFilterCount,
    usedUnitAdvancedFilterCount,
  };
};

export const useMapFiltersQsAndCount = () => {
  const mapFilters = useMapStore((state) => state.mapFilters);

  const {
    newProjectQs,
    newProjectFilterCount,
    newProjectBasicFilterCount,
    newProjectAdvancedFilterCount,
    usedUnitQs,
    usedUnitFilterCount,
    usedUnitBasicFilterCount,
    usedUnitAdvancedFilterCount,
  } = useMemo(() => prepareMapFiltersQueryString(mapFilters), [mapFilters]);

  return {
    mapFiltersQs: { newProjectQs, usedUnitQs },
    filterCount: {
      newProjectFilterCount,
      newProjectBasicFilterCount,
      newProjectAdvancedFilterCount,
      usedUnitFilterCount,
      usedUnitBasicFilterCount,
      usedUnitAdvancedFilterCount,
      totalFilters: newProjectFilterCount + usedUnitFilterCount,
    },
  };
};

export const useModifiedFilters = () => {
  const [modifiedFilters, setModifiedFilters] = useState<{
    [key: string]: any;
  }>({});
  const mapFilters = useMapStore((state) => state.mapFilters);

  useEffect(() => {
    const updatedFilters: { [key: string]: unknown } = {};

    Object.keys(mapFilters).forEach((key) => {
      const current = mapFilters[key];
      const initial = initialMapFilters[key];

      if (current.type === MapFilterType.CHECKBOX) {
        const filteredValues = current.values
          .filter((item) => item.checked)
          .map((item) => item.id);

        if (filteredValues.length > 0) {
          updatedFilters[current.apiParam as string] = filteredValues;
        }
      } else if (current.type === MapFilterType.CHECKBOX_FLAG) {
        current.values.forEach((item, index) => {
          if (item.checked) {
            updatedFilters[current.apiParam[index]] = 'false';
          }
        });
      } else if (
        current.type === MapFilterType.DATE_PICKER ||
        current.type === MapFilterType.DATE_SELECTOR
      ) {
        const minDateParam = current.apiParam[1];
        const maxDateParam = current.apiParam[0];

        if (current.values.minDate) {
          updatedFilters[minDateParam] = format(
            current.values.minDate,
            'yyyy-MM-dd'
          );
        }

        if (current.values.maxDate) {
          updatedFilters[maxDateParam] = format(
            current.values.maxDate,
            'yyyy-MM-dd'
          );
        }
      } else if (current.type === MapFilterType.RANGE) {
        const [minParam, maxParam] = current.apiParam;

        if (current.values[0] !== null) {
          updatedFilters[minParam] = current.values[0];
        }

        if (current.values[1] !== null) {
          updatedFilters[maxParam] = current.values[1];
        }
      } else if (
        JSON.stringify(current.values) !== JSON.stringify(initial.values)
      ) {
        if (current.values !== null) {
          updatedFilters[key] = current.values;
        }
      }
    });
    setModifiedFilters(updatedFilters);
  }, [mapFilters]);

  return modifiedFilters;
};
