import { useEffect } from 'react';
import keyboard, { KeyHandler } from 'keymaster';

import useGuid from './use-guid';

export type Keys = {
  [key: string]: KeyHandler;
};

keyboard.filter = (e) => {
  const tagName = e.target!.tagName;
  return !(
    // tagName === 'INPUT' ||
    (tagName === 'SELECT' || tagName === 'TEXTAREA')
  );
};

export function useKeys(
  name: string = 'keys',
  active = false,
  keys: Keys = {}
) {
  const guid = useGuid(name);

  useEffect(() => {
    if (guid !== null) {
      Object.keys(keys).forEach((key) => {
        keyboard(`${key}`, guid, keys[key]);
      });
    }

    return function cleanup() {
      if (guid) {
        keyboard.deleteScope(guid);
      }
    };
  }, [guid, keys]);

  useEffect(() => {
    if (active) {
      if (guid) {
        keyboard.setScope(guid);
      }
    } else {
      if (keyboard.getScope() === guid) {
        keyboard.setScope('all');
      }
    }
  }, [active, guid]);
}

export default useKeys;
