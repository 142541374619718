import { SuitcaseSimple, Users } from '@phosphor-icons/react';
import { NavLink } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { SidebarButton } from '@/components/sidebar';

export function ManageMenu() {
  const t = useTranslations('Sidebar.ContextMenu.ManageMenu');
  // const featureInvoices =
  //   (import.meta.env.VITE_FEATURE_INVOICES || 'true') === 'true';

  const pages = [
    {
      name: t('settingsLink'),
      Icon: SuitcaseSimple,
      path: '../settings',
    },
    {
      name: t('membersLink'),
      Icon: Users,
      path: '../members',
    },
    // {
    //   name: t('subscriptionLink'),
    //   Icon: Star,
    //   path: '../subscription',
    // },
  ];

  // if (featureInvoices) {
  //   pages.push({
  //     name: t('invoicesLink'),
  //     Icon: Money,
  //     path: '../invoices',
  //   });
  // }

  return (
    <ul className="flex flex-col">
      {pages.map(({ name, Icon, path }) => (
        <li className="my-1" key={path}>
          <NavLink to={path} relative="path">
            {(props) => (
              <SidebarButton as="span" {...props}>
                <Icon className="mr-2 h-5 w-5" /> {name}
              </SidebarButton>
            )}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default ManageMenu;
