import { ZodErrorMap, ZodIssueCode, ZodParsedType, util } from 'zod';

const errorMap: ZodErrorMap = (issue, _ctx) => {
  let message: string;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = 'Påkrevd';
      } else {
        message = `Forventet ${issue.expected}, mottok ${issue.received}`;
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = ` Ugyldig bokstavelig verdi, forventet ${JSON.stringify(
        issue.expected,
        util.jsonStringifyReplacer
      )}`;
      break;
    case ZodIssueCode.unrecognized_keys:
      message = `Ugjenkjente nøkkel(er) i objektet: ${util.joinValues(
        issue.keys,
        ', '
      )}`;
      break;
    case ZodIssueCode.invalid_union:
      message = `Ugyldig inndata`;
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = ` Ugyldig diskriminatorverdi. Forventet ${util.joinValues(
        issue.options
      )}`;
      break;
    case ZodIssueCode.invalid_enum_value:
      message = ` Ugyldig enum-verdi. Forventet ${util.joinValues(
        issue.options
      )}, mottok '${issue.received}'`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = ` Ugyldige funksjonsargumenter`;
      break;
    case ZodIssueCode.invalid_return_type:
      message = ` Ugyldig funksjonsreturtype`;
      break;
    case ZodIssueCode.invalid_date:
      message = ` Ugyldig dato`;
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === 'object') {
        if ('includes' in issue.validation) {
          message = `Ugyldig inndata: må inkludere "${issue.validation.includes}"`;

          if (typeof issue.validation.position === 'number') {
            message = `${message} på en eller flere posisjoner større enn eller lik ${issue.validation.position}`;
          }
        } else if ('startsWith' in issue.validation) {
          message = `Ugyldig inndata: må starte med "${issue.validation.startsWith}"`;
        } else if ('endsWith' in issue.validation) {
          message = `Ugyldig inndata: må slutte med "${issue.validation.endsWith}"`;
        } else {
          util.assertNever(issue.validation);
        }
      } else if (issue.validation !== 'regex') {
        message = ` Ugyldig ${issue.validation}`;
      } else {
        message = ' Ugyldig';
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === 'array')
        message = `Listen må inneholde ${
          issue.exact ? 'nøyaktig' : issue.inclusive ? `minst` : `mer enn`
        } ${issue.minimum} element(er)`;
      else if (issue.type === 'string')
        message = `Strengen må inneholde ${
          issue.exact ? 'nøyaktig' : issue.inclusive ? `minst` : `over`
        } ${issue.minimum} tegn`;
      else if (issue.type === 'number')
        message = `Tallet må være ${
          issue.exact
            ? `nøyaktig lik `
            : issue.inclusive
            ? `større enn eller lik `
            : `større enn `
        }${issue.minimum}`;
      else if (issue.type === 'date')
        message = `Datoen må være ${
          issue.exact
            ? `nøyaktig lik `
            : issue.inclusive
            ? `større enn eller lik `
            : `større enn `
        }${new Date(Number(issue.minimum)).toDateString()}`;
      else message = 'Ugyldig inndata';
      break;
    case ZodIssueCode.too_big:
      if (issue.type === 'array')
        message = `Listen må inneholde ${
          issue.exact ? `nøyaktig` : issue.inclusive ? `høyst` : `mindre enn`
        } ${issue.maximum} element(er)`;
      else if (issue.type === 'string')
        message = `Strengen må inneholde ${
          issue.exact ? `nøyaktig` : issue.inclusive ? `høyst` : `under`
        } ${issue.maximum} tegn`;
      else if (issue.type === 'number')
        message = `Tallet må være ${
          issue.exact
            ? `nøyaktig`
            : issue.inclusive
            ? `mindre enn eller lik`
            : `mindre enn`
        } ${issue.maximum}`;
      else if (issue.type === 'bigint')
        message = `BigInt må være ${
          issue.exact
            ? `nøyaktig`
            : issue.inclusive
            ? `mindre enn eller lik`
            : `mindre enn`
        } ${issue.maximum}`;
      else if (issue.type === 'date')
        message = `Datoen må være ${
          issue.exact
            ? `nøyaktig`
            : issue.inclusive
            ? `mindre enn eller lik`
            : `mindre enn`
        } ${new Date(Number(issue.maximum)).toDateString()}`;
      else message = 'Ugyldig inndata';
      break;
    case ZodIssueCode.custom:
      message = `Ugyldig inndata`;
      break;
    case ZodIssueCode.invalid_intersection_types:
      message = `Krysningsresultatene kunne ikke slås sammen.`;
      break;
    case ZodIssueCode.not_multiple_of:
      message = `Tallet må være et multiplum av ${issue.multipleOf}`;
      break;
    case ZodIssueCode.not_finite:
      message = 'Tallet må være endelig';
      break;
    default:
      message = _ctx.defaultError;
      util.assertNever(issue);
  }
  return { message };
};

export default errorMap;
