import { useEffect, useRef, useState } from 'react';

let counter = 0;

export function useGuid(name = 'guid') {
  const ref = useRef<string | null>(null);
  const [guid, setGuid] = useState<string | null>(null);

  useEffect(() => {
    counter += 1;
    ref.current = `${name}-${counter}`;
    return function cleanup() {
      counter -= 1;
    };
  }, [name]);

  useEffect(() => {
    if (ref.current) {
      setGuid(ref.current);
    }
  }, [name]);
  return guid;
}

export default useGuid;
