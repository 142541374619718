import { useQuery, type UseQueryOptions } from 'react-query';

import { type ListWorkspaceDto } from '@plot/plot-api';

import { useApi } from '@/lib/api';
import { useAuth, useLocale } from '@/lib/contexts';

export function useWorkspaces(
  options: Omit<
    UseQueryOptions<ListWorkspaceDto | undefined>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const { api } = useApi();
  const { authenticated, token } = useAuth();
  const { locale } = useLocale();

  return useQuery(
    ['workspaces', authenticated, token?.access, locale],
    () => {
      if (!authenticated) return undefined;

      return api.workspace.list();
    },
    options
  );
}
