import { useMutation, useQueryClient } from 'react-query';

import {
  type ProfileDto,
  type UpdatePasswordRequestDto,
  type UpdateProfileRequestDto,
} from '@plot/plot-api';

import { useApi } from '@/lib/api';
import { useAuth } from '@/lib/contexts';

export function useProfileMutations() {
  const queryClient = useQueryClient();
  const { api } = useApi();
  const { authenticated, token } = useAuth();

  return {
    patch: useMutation({
      mutationFn: (data) => api.profile.patch(data),
      // When mutate is called:
      onMutate: async (profile: UpdateProfileRequestDto) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          'profile',
          authenticated,
          token?.access,
        ]);

        // Snapshot the previous value
        const prev = queryClient.getQueryData<ProfileDto>([
          'profile',
          authenticated,
          token?.access,
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData<ProfileDto>(
          ['profile', authenticated, token?.access],
          (prev) => {
            return {
              ...prev,
              ...profile,
            };
          }
        );

        return { prev };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.prev) {
          queryClient.setQueryData<UpdateProfileRequestDto>(
            ['profile', authenticated, token?.access],
            context.prev
          );
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<ProfileDto>(
          ['profile', authenticated, token?.access],
          data
        );
      },
    }),

    updatePassword: useMutation((data: UpdatePasswordRequestDto) =>
      api.profile.updatePassword(data)
    ),
  };
}
