import { Suspense, lazy } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter } from 'react-router-dom';

import { Loader } from '@plot/ui';

import { TrackAreaReportViewed } from '@/lib/analytics/components';
import { AreaReportTrackerProvider } from '@/lib/analytics/contexts/area-report';
import { msalConfig } from '@/lib/msal';
import NotFoundPage from '@/pages/not-found/NotFound';

import {
  AppLayout,
  AuthLayout,
  PageLayout,
  WorkspaceLayout,
} from '@/components/layouts';

const AreaReport = lazy(() => import('../pages/area-report'));
// const Wizard = lazy(() => import('../pages/workspace/_wizard'));
const Settings = lazy(() => import('../pages/workspace/settings'));
const Members = lazy(() => import('../pages/workspace/members'));
// const Subscription = lazy(
//   () => import('../pages/workspace/_wizard/subscription')
// );
// const Invoices = lazy(() => import('../pages/workspace/_invoices'));
const Profile = lazy(() => import('../pages/account/profile'));
const Billing = lazy(() => import('../pages/account/billing'));
const Signin = lazy(() => import('../pages/auth/signin'));
const Signup = lazy(() => import('../pages/auth/signup'));
const Verify = lazy(() => import('../pages/auth/signup/verify'));
const ForgotPassword = lazy(() => import('../pages/auth/forgot-password'));
const ResetPassword = lazy(() => import('../pages/auth/reset-password'));
const NotSupported = lazy(() => import('../pages/auth/not-supported'));
const Error = lazy(() => import('../pages/workspace/error'));
const Welcome = lazy(() => import('../pages/workspace/welcome'));

const msalInstance = new PublicClientApplication(msalConfig);

const authRoutes = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: 'signin',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <GoogleOAuthProvider
                  clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <MsalProvider instance={msalInstance}>
                    <Signin />
                  </MsalProvider>
                </GoogleOAuthProvider>
              </Suspense>
            ),
          },
          {
            path: 'forgot-password',
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <ForgotPassword />
              </Suspense>
            ),
          },
          {
            path: 'reset-password/:user/:token',
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <ResetPassword />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'signup',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <GoogleOAuthProvider
                  clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <MsalProvider instance={msalInstance}>
                    <Signup />
                  </MsalProvider>
                </GoogleOAuthProvider>
              </Suspense>
            ),
          },
          {
            path: 'verify',
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <Verify />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];

const workspaceSettingsRoutes = [
  // {
  //   path: '/workspaces/create',
  //   element: (
  //     <Suspense fallback={<Loader backdrop cover />}>
  //       <Wizard />
  //     </Suspense>
  //   ),
  // },
  {
    path: '/workspaces/:workspace/setup',
    element: (
      // <Suspense fallback={<Loader backdrop cover />}>
      //   <Wizard />
      // </Suspense>
      <Suspense fallback={<Loader backdrop cover />}>
        <Welcome />
      </Suspense>
    ),
  },
  {
    path: '/workspaces/suspended',
    element: (
      <Suspense fallback={<Loader backdrop cover />}>
        <Error type="suspended" />
      </Suspense>
    ),
  },
  {
    path: '/workspaces/inactive',
    element: (
      <Suspense fallback={<Loader backdrop cover />}>
        <Error type="inactive" />
      </Suspense>
    ),
  },
  {
    path: '/workspaces/:workspace',
    element: <PageLayout />,
    children: [
      {
        path: 'settings',
        element: (
          <Suspense fallback={<Loader backdrop cover />}>
            <Settings />
          </Suspense>
        ),
      },
      {
        path: 'members',
        element: (
          <Suspense fallback={<Loader backdrop cover />}>
            <Members />
          </Suspense>
        ),
      },
      // {
      //   path: 'subscription',
      //   element: (
      //     <Suspense fallback={<Loader backdrop cover />}>
      //       <Subscription showHeader />
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: 'invoices',
      //   element: (
      //     <Suspense fallback={<Loader backdrop cover />}>
      //       <Invoices />
      //     </Suspense>
      //   ),
      // },
    ],
  },
];

const accountSettingsRoutes = [
  {
    element: <PageLayout />,
    children: [
      {
        path: 'profile',
        element: (
          <Suspense fallback={<Loader backdrop cover />}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: 'billing',
        element: (
          <Suspense fallback={<Loader backdrop cover />}>
            <Billing />
          </Suspense>
        ),
      },
    ],
  },
];

const otherRoutes = [
  {
    children: [
      {
        path: '/not-supported',
        element: <NotSupported />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

const workspaceRoutes = [
  {
    element: <WorkspaceLayout />,
    children: [
      {
        index: true,
        element: null,
      },
      ...workspaceSettingsRoutes,
      ...accountSettingsRoutes,
      {
        path: '/workspaces/:workspace/area-reports',
        children: [
          {
            index: true,
            path: ':reportId?',
            element: (
              <AreaReportTrackerProvider>
                <TrackAreaReportViewed>
                  <Suspense fallback={<Loader backdrop cover />}>
                    <AreaReport />
                  </Suspense>
                </TrackAreaReportViewed>
              </AreaReportTrackerProvider>
            ),
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [...workspaceRoutes, ...authRoutes, ...otherRoutes],
  },
]);

export default router;
