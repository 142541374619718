import { useState } from 'react';

import { useAreaReportTracker } from '@/lib/analytics/contexts';

interface Props {
  children: React.ReactElement;
}

export function TrackAreaReportViewed({ children }: Props) {
  const [alreadyTriggered, markTriggered] = useState(false);
  const tracker = useAreaReportTracker();

  if (tracker && !alreadyTriggered) {
    tracker.areaReportViewed();
    markTriggered(true);
  }

  return children;
}

export default TrackAreaReportViewed;
