import React, { createContext, useContext, useEffect, useState } from 'react';

interface Type<AClass> {
  new (...args: any[]): AClass;
}

interface TrackerContextType<Tracker> {
  tracker: Tracker | undefined;
}

export function createTrackerProvider<Tracker, TrackerBoundData>(
  TrackerClass: Type<Tracker>,
  provideBoundData: () => {
    boundData: Partial<TrackerBoundData>;
    cacheKeys: Array<any>;
  }
) {
  const TrackerContext = createContext<TrackerContextType<Tracker> | undefined>(
    undefined
  );

  type TrackerProviderProps = {
    children: React.ReactNode;
  };

  function TrackerProvider({ children }: TrackerProviderProps) {
    const { boundData, cacheKeys } = provideBoundData();
    const [tracker, setTracker] = useState<Tracker | undefined>();

    useEffect(
      () =>
        setTracker(
          Object.values(boundData).every(Boolean)
            ? new TrackerClass(boundData)
            : undefined
        ),
      cacheKeys
    );

    return (
      <TrackerContext.Provider
        value={{
          tracker,
        }}
      >
        {children}
      </TrackerContext.Provider>
    );
  }

  function useTracker() {
    return useContext(TrackerContext)?.tracker;
  }

  function useTrackerOnce(
    waitUntil: () => boolean,
    triggerTracker: (tracker: Tracker) => void,
    cacheKey: string | number | Array<any> = 'defaultKey'
  ) {
    const [alreadyTriggered, setTriggered] = useState(new Map());
    const tracker = useTracker();

    if (
      tracker &&
      !alreadyTriggered.has(JSON.stringify(cacheKey)) &&
      waitUntil()
    ) {
      triggerTracker(tracker);
      setTriggered(
        new Map([
          ...alreadyTriggered.entries(),
          [JSON.stringify(cacheKey), true],
        ])
      );
    }
  }

  return {
    TrackerContext,
    TrackerProvider,
    useTracker,
    useTrackerOnce,
  };
}
