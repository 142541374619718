import * as core from '../core';

export declare interface BaseBoundData {
  user: { id?: string; email?: string };
}

export class BaseTracker<BoundData extends BaseBoundData> {
  boundData: BoundData;

  constructor(boundData: BoundData) {
    this.boundData = boundData;
  }

  track(eventName: string, eventProperties: object = {}) {
    core.track(eventName, eventProperties);
  }
}
