import { createContext, useContext, useEffect, useRef } from 'react';

import { ProfileDto } from '@plot/plot-api';

import * as analytics from '@/lib/analytics/';
import { useProfile as useProfileApi, useProfileMutations } from '@/lib/api';

import { useAuth } from './auth';
import { getPreferredLocale, useLocale, type Locale } from './locale';

interface ProfileContextType {
  profile: ProfileDto | undefined;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

type ProfileProviderProps = {
  children: React.ReactNode;
};

export function ProfileProvider({ children }: ProfileProviderProps) {
  const busy = useRef(false);
  const { authenticated } = useAuth();
  const { patch } = useProfileMutations();
  const profile = useProfileApi();
  const status = profile.status;
  const profileLanguage = profile.data?.language;
  const profileId = profile.data?.id;
  const profileEmail = profile.data?.email;
  const preferredLanguage = getPreferredLocale();
  const { setLocale } = useLocale();

  useEffect(() => {
    if (status === 'success' && !busy.current && authenticated) {
      if (!profileLanguage) {
        const data = {
          language: preferredLanguage,
        } as ProfileDto;

        try {
          busy.current = true;
          patch
            .mutateAsync(data)
            .then(() => {
              busy.current = false;
            })
            .catch(() => {
              busy.current = false;
            });
        } catch (err) {
          console.log('UPDATE PROFILE LANGUAGE ERR!', err);
        }
      }
    }
  }, [status, profileLanguage, preferredLanguage, authenticated]);

  useEffect(() => {
    if (status === 'success') {
      if (profileLanguage) {
        setLocale(profileLanguage as Locale);
      }
    }
  }, [status, profileLanguage]);

  useEffect(() => {
    if (status === 'success') {
      analytics.core.setUser(
        profileId,
        profileEmail,
        profile.data?.trackingAudience
      );
    }
  }, [status, profileEmail]);

  return (
    <ProfileContext.Provider
      value={{
        profile: profile.data,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfile() {
  const context = useContext(ProfileContext);

  if (context === undefined) {
    throw new Error('useProfile must be used within an ProfileProvider');
  }

  return context.profile;
}
