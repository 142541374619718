// src/api/Auth/Auth.ts
var Auth = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async signin(body) {
    return this.client.post("/v1/accounts/auth/token", {
      body
    });
  }
  async signinWithGoogle(body) {
    return this.client.post("/v1/accounts/auth/oauth/google", {
      body
    });
  }
  async signinWithMicrosoft(body) {
    return this.client.post(
      "/v1/accounts/auth/oauth/microsoft",
      {
        body
      }
    );
  }
  async resetPasswordRequest(body) {
    return this.client.post(
      "/v1/accounts/auth/password-reset/request",
      { body }
    );
  }
  async resetPasswordConfirm(body) {
    return this.client.post(
      "/v1/accounts/auth/password-reset/confirm",
      { body }
    );
  }
  async verifyToken(body) {
    return this.client.post("/v1/accounts/auth/token/verify", {
      body
    });
  }
  async refreshToken(body) {
    return this.client.post(
      "/v1/accounts/auth/token/refresh",
      {
        body
      }
    );
  }
};

// src/api/Billing/Account/PaymentCard/PaymentCard.ts
var PaymentCard = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async create(id) {
    return this.client.post(
      `/v1/accounts/billing/accounts/${id}/payment-card`
    );
  }
};
var PaymentCard_default = PaymentCard;

// src/api/Billing/Account/Account.ts
var BillingAccount = class {
  client;
  paymentCard;
  constructor(client) {
    this.client = client;
    this.paymentCard = new PaymentCard_default(this.client);
  }
  async list() {
    return this.client.get(
      `/v1/accounts/billing/accounts`,
      { searchParams: { hide_blank: true } }
    );
  }
  async create(body) {
    return this.client.post(
      "/v1/accounts/billing/accounts",
      { body }
    );
  }
  async get(id) {
    return this.client.get(
      `/v1/accounts/billing/accounts/${id}`
    );
  }
  async update(id, body) {
    return this.client.put(
      `/v1/accounts/billing/accounts/${id}`,
      { body }
    );
  }
  async patch(id, body) {
    return this.client.patch(
      `/v1/accounts/billing/accounts/${id}`,
      { body }
    );
  }
  async remove(id) {
    return this.client.delete(
      `/v1/accounts/billing/accounts/${id}`
    );
  }
};
var Account_default = BillingAccount;

// src/api/Billing/Country/Country.ts
var Country = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async list() {
    return this.client.get(`/v1/accounts/billing/countries`);
  }
};
var Country_default = Country;

// src/api/Billing/Billing.ts
var Billing = class {
  client;
  account;
  country;
  constructor(client) {
    this.client = client;
    this.account = new Account_default(this.client);
    this.country = new Country_default(this.client);
  }
};

// src/api/Feedback/Feedback.ts
var Feedback = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async createTicket(body) {
    return this.client.post(
      `/v1/accounts/feedback-tickets`,
      { body }
    );
  }
};

// src/api/Geodata/Area/Area.ts
var Area = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async list(workspaceId, query, signal) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/areas`,
      { searchParams: query, signal }
    );
  }
  async get(workspaceId, areaId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/areas/${areaId}`
    );
  }
};
var Area_default = Area;

// src/api/Geodata/AreaReport/AreaReport.ts
var AreaReport = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async demographicsAge(workspaceId, reportId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/demographics/age`
    );
  }
  async demographicsAgeForecasts(workspaceId, reportId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/demographics/age/forecasts`
    );
  }
  async demographicsComposition(workspaceId, reportId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/demographics/composition`
    );
  }
  async pricesDistribution(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/prices/distribution`,
      { searchParams }
    );
  }
  async pricesPercentiles(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/prices/percentiles`,
      { searchParams }
    );
  }
  async propertyCadastre(workspaceId, reportId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/property/cadastre`
    );
  }
  async propertyCounts(workspaceId, reportId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/property/counts`
    );
  }
  async propertyUnits(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/property/units-table`,
      { searchParams }
    );
  }
  async propertyUnitMix(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/property/unitmix-table2`,
      { searchParams }
    );
  }
  async salesTimes(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/sales/times`,
      { searchParams }
    );
  }
  async salesPropertyTypes(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/sales/property-types`,
      { searchParams }
    );
  }
  async salesVolumes(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/sales/volumes`,
      { searchParams }
    );
  }
  async salesAbsorption(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/sales/absorption`,
      { searchParams }
    );
  }
  async salesListed(workspaceId, reportId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/area-reports/${reportId}/sales/listed`,
      { searchParams }
    );
  }
};
var AreaReport_default = AreaReport;

// src/api/Geodata/ChartSources/ChartSources.ts
var ChartSources = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async demographicsAge(workspaceId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/demographics/age/`
    );
  }
  async demographicsAgeForecasts(workspaceId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/demographics/age/forecasts/`
    );
  }
  async demographicsComposition(workspaceId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/demographics/composition/`
    );
  }
  async pricesDistribution(workspaceId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/prices/distribution/`
    );
  }
  async pricesPercentiles(workspaceId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/prices/percentiles/`
    );
  }
  async propertyCadastre(workspaceId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/property/cadastre/`
    );
  }
  async propertyCounts(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/property/counts/`,
      { searchParams }
    );
  }
  async propertyProjectsTable(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/property/projects-table/`,
      { searchParams }
    );
  }
  async propertySummary(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/property/summary/`,
      { searchParams }
    );
  }
  async propertyUnitMixTable(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/property/unitmix-table2/`,
      { searchParams }
    );
  }
  async propertyFloorUnitMixTable(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/property/floor-unitmix-table/`,
      { searchParams }
    );
  }
  async propertyUnitsTable(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/property/units-table/`,
      { searchParams }
    );
  }
  async salesProgression(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/progression/`,
      { searchParams }
    );
  }
  async salesProgressionUnitMix(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/progression-unitmix/`,
      { searchParams }
    );
  }
  async salesPropertyTypes(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/property-types/`,
      { searchParams }
    );
  }
  async salesTimes(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/times/`,
      { searchParams }
    );
  }
  async salesVolumes(workspaceId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/volumes/`
    );
  }
  async salesAbsorption(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/absorption/`,
      { searchParams }
    );
  }
  async salesListed(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/listed/`,
      { searchParams }
    );
  }
  async salesSqmPrice(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/sqmprice/`,
      { searchParams }
    );
  }
  async salesSqmPricePs(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/chart-sources/sales/sqmprice-ps/`,
      { searchParams }
    );
  }
};

// src/api/Geodata/Property/Property.ts
var Property = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async plot(workspaceId, plotId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/properties/plots/${plotId}`
    );
  }
  async projectStageSearch(workspaceId, query, signal) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/properties/project-stages`,
      { searchParams: query, signal }
    );
  }
  async projectStage(workspaceId, projectStageId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/properties/project-stages/${projectStageId}`,
      { searchParams }
    );
  }
  async unit(workspaceId, propertyId) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/properties/units/${propertyId}`
    );
  }
  async units(workspaceId, projectId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/properties/project-stages/${projectId}/units/`,
      { searchParams }
    );
  }
};
var Property_default = Property;

// src/api/Geodata/SpreadsheetSources/SpreadsheetSources.ts
var SpreadsheetSources = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async downloadMarketDataStages(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/spreadsheet-sources/market-data-stages/`,
      { searchParams, responseType: "blob" }
    );
  }
  async downloadMarketDataUnits(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/spreadsheet-sources/market-data-units/`,
      { searchParams, responseType: "blob" }
    );
  }
  async downloadMarketInsights(workspaceId, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/spreadsheet-sources/market-insights/`,
      { searchParams, responseType: "blob" }
    );
  }
  async downloadProjectStagesUnits(workspaceId, project_stage_id, searchParams) {
    return this.client.get(
      `/v1/geodata/workspaces/${workspaceId}/spreadsheet-sources/project-stages/${project_stage_id}/units`,
      { searchParams, responseType: "blob" }
    );
  }
};
var SpreadsheetSources_default = SpreadsheetSources;

// src/api/Geodata/Geodata.ts
var Geodata = class {
  area;
  areaReport;
  property;
  chartSources;
  spreadsheetSources;
  constructor(client) {
    this.area = new Area_default(client);
    this.areaReport = new AreaReport_default(client);
    this.property = new Property_default(client);
    this.chartSources = new ChartSources(client);
    this.spreadsheetSources = new SpreadsheetSources_default(client);
  }
};

// src/api/Profile/Profile.ts
var Profile = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async get() {
    return this.client.get("/v1/accounts/profile");
  }
  async update(body) {
    return this.client.put("/v1/accounts/profile", { body });
  }
  async patch(body) {
    return this.client.patch("/v1/accounts/profile", { body });
  }
  async updatePassword(body) {
    return this.client.post("/v1/accounts/profile/password", {
      body
    });
  }
  async remove() {
    return this.client.delete("/v1/accounts/profile");
  }
};

// src/api/Registration/Registration.ts
var Registration = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async create(body) {
    return this.client.post(
      `/v1/accounts/registration-sessions`,
      { body }
    );
  }
  async get(id) {
    return this.client.get(
      `/v1/accounts/registration-sessions/${id}`
    );
  }
  async resendVerification(id) {
    return this.client.post(
      `/v1/accounts/registration-sessions/${id}/resend`
    );
  }
  async verifyAccount(id, body) {
    return this.client.post(
      `/v1/accounts/registration-sessions/${id}/verify`,
      { body }
    );
  }
};

// src/api/SubscriptionPlan/SubscriptionPlan.ts
var SubscriptionPlan = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async list() {
    return this.client.get(
      `/v1/accounts/billing/subscription-plans`
    );
  }
};

// src/api/Workspace/AreaReport/Area/Area.ts
var Area2 = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async list(workspaceId, reportId) {
    return this.client.get(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${reportId}/areas`
    );
  }
  async create(workspaceId, reportId, body) {
    return this.client.post(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${reportId}/areas`,
      { body }
    );
  }
  async get(workspaceId, reportId, id) {
    return this.client.get(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${reportId}/areas/${id}`
    );
  }
  async update(workspaceId, reportId, body) {
    return this.client.put(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${reportId}/areas/${body.id}`,
      { body }
    );
  }
  async patch(workspaceId, reportId, body) {
    return this.client.patch(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${reportId}/areas/${body.id}`,
      { body }
    );
  }
  async remove(workspaceId, reportId, id) {
    return this.client.delete(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${reportId}/areas/${id}`
    );
  }
};
var Area_default2 = Area2;

// src/api/Workspace/AreaReport/AreaReport.ts
var AreaReport2 = class {
  client;
  area;
  constructor(client) {
    this.client = client;
    this.area = new Area_default2(client);
  }
  async list(workspaceId) {
    return this.client.get(
      `/v1/accounts/workspaces/${workspaceId}/area-reports`
    );
  }
  async create(workspaceId, body) {
    return this.client.post(
      `/v1/accounts/workspaces/${workspaceId}/area-reports`,
      { body }
    );
  }
  async get(workspaceId, id) {
    return this.client.get(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${id}`
    );
  }
  async update(workspaceId, body) {
    return this.client.put(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${body.id}`,
      { body }
    );
  }
  async patch(workspaceId, body) {
    return this.client.patch(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${body.id}`,
      { body }
    );
  }
  async remove(workspaceId, id) {
    return this.client.delete(
      `/v1/accounts/workspaces/${workspaceId}/area-reports/${id}`
    );
  }
};
var AreaReport_default2 = AreaReport2;

// src/api/Workspace/Member/Member.ts
var Member = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async list(workspaceId) {
    return this.client.get(
      `/v1/accounts/workspaces/${workspaceId}/members`
    );
  }
  async invite(workspaceId, body) {
    return this.client.post(
      `/v1/accounts/workspaces/${workspaceId}/members`,
      { body }
    );
  }
  async get(workspaceId, id) {
    return this.client.get(
      `/v1/accounts/workspaces/${workspaceId}/members/${id}`
    );
  }
  async remove(workspaceId, id) {
    return this.client.delete(
      `/v1/accounts/workspaces/${workspaceId}/members/${id}`
    );
  }
};
var Member_default = Member;

// src/api/Workspace/Subscription/Subscription.ts
var Subscription = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async create(id, body) {
    return this.client.post(
      `/v1/accounts/workspaces/${id}/subscription`,
      { body }
    );
  }
  async resume(id) {
    return this.client.post(
      `/v1/accounts/workspaces/${id}/subscription/resume`
    );
  }
  async get(id) {
    return this.client.get(
      `/v1/accounts/workspaces/${id}/subscription`
    );
  }
  async update(id, body) {
    return this.client.put(
      `/v1/accounts/workspaces/${id}/subscription`,
      { body }
    );
  }
  async patch(id, body) {
    return this.client.patch(
      `/v1/accounts/workspaces/${id}/subscription`,
      { body }
    );
  }
  async remove(id, body) {
    return this.client.delete(
      `/v1/accounts/workspaces/${id}/subscription`,
      { body }
    );
  }
};
var Subscription_default = Subscription;

// src/api/Workspace/Usage/Usage.ts
var Usage = class {
  client;
  constructor(client) {
    this.client = client;
  }
  async get(id) {
    return this.client.get(`/v1/accounts/workspaces/${id}/usage`);
  }
};
var Usage_default = Usage;

// src/api/Workspace/Workspace.ts
var Workspace = class {
  client;
  member;
  areaReport;
  subscription;
  usage;
  constructor(client) {
    this.client = client;
    this.member = new Member_default(this.client);
    this.areaReport = new AreaReport_default2(this.client);
    this.subscription = new Subscription_default(this.client);
    this.usage = new Usage_default(this.client);
  }
  async list() {
    return this.client.get(
      "/v1/accounts/workspaces"
    );
  }
  async create(body) {
    return this.client.post("/v1/accounts/workspaces", { body });
  }
  async get(id) {
    return this.client.get(`/v1/accounts/workspaces/${id}`);
  }
  async update(body) {
    return this.client.put(`/v1/accounts/workspaces/${body.id}`, {
      body
    });
  }
  async patch(body) {
    return this.client.patch(
      `/v1/accounts/workspaces/${body.id}`,
      { body }
    );
  }
  async remove(id) {
    return this.client.delete(`/v1/accounts/workspaces/${id}`);
  }
  async leave(id) {
    return this.client.post(`/v1/accounts/workspaces/${id}/leave`);
  }
  async completeSetup(id, body) {
    return this.client.post(
      `/v1/accounts/workspaces/${id}/complete-setup`,
      { body }
    );
  }
};

// src/config.ts
function getApiUrl() {
  const API_URL2 = import.meta.env.VITE_API_URL;
  return API_URL2.endsWith("/") ? import.meta.env.VITE_API_URL.slice(0, -1) : import.meta.env.VITE_API_URL;
}
var API_URL = getApiUrl();

// src/errors/ApiError.ts
var ApiError = class extends Error {
  status;
  data;
  options;
  constructor(message, status, options, data) {
    let serverMessage = message;
    if (data) {
      switch (true) {
        case data.nonFieldErrors?.length > 0:
          serverMessage = data.nonFieldErrors[0];
          break;
        case data.code?.length > 0:
          serverMessage = data.code[0];
          break;
        case data.plan?.length > 0:
          serverMessage = data.plan[0];
          break;
        case data.detail?.length > 0:
          serverMessage = data.detail;
          break;
      }
    }
    super(serverMessage, options);
    this.status = status ?? 0;
    this.data = data ?? {};
    this.options = options ?? {};
  }
  getServerMessage() {
    if (this.data) {
      if (this.data.nonFieldErrors?.length > 0) {
        return this.data.nonFieldErrors[0];
      }
      if (this.data.code?.length > 0) {
        return this.data.code[0];
      }
      if (this.data.plan?.length > 0) {
        return this.data.plan[0];
      }
      if (this.data.detail) {
        return this.data.detail;
      }
    }
    return this.message;
  }
};

// src/errors/ApiNetworkError.ts
var ApiNetworkError = class extends Error {
};

// src/client/client.ts
var ApiClient = class {
  token;
  locale;
  onError;
  // Callback to handle errors
  constructor(token, locale) {
    this.token = token;
    this.locale = locale;
  }
  async api(path, {
    method = "GET",
    headers: h = {},
    body,
    token,
    locale,
    searchParams = {},
    responseType = "json",
    signal
  } = {}) {
    if (!API_URL)
      throw new Error("Missing API URL");
    const url = this.constructUrl(path, searchParams);
    const options = this.constructOptions(
      method,
      h,
      body,
      token,
      locale,
      responseType,
      signal
    );
    let response;
    try {
      response = await fetch(url, options);
    } catch (err) {
      this.handleNetworkError();
    }
    if (response.ok) {
      switch (responseType) {
        case "blob":
          return {
            data: response.blob(),
            filename: response.headers.get("Content-Disposition")?.match(/filename="([^"]+)"/)?.[1] ?? null
          };
        case "json":
        default:
          return response.headers.get("Content-Type")?.includes("application/json") ? response.json() : response.text();
      }
    }
    return await this.handleApiError(response, url, options, body);
  }
  async handleApiError(response, url, options, body) {
    const data = response.headers.get("Content-Type")?.includes("application/json") ? await response.json() : await response.text();
    const message = data && typeof data.detail === "string" ? data.detail : `${response.status} ${response.statusText}`;
    const errorOptions = {
      cause: JSON.stringify({
        request: {
          url: url.toString(),
          options,
          body
        },
        response: data
      })
    };
    const apiError = new ApiError(
      message,
      response.status,
      errorOptions,
      data
    );
    if (this.onError)
      this.onError(apiError);
    throw apiError;
  }
  constructUrl(path, searchParams = {}) {
    const BASE_URL = API_URL.endsWith("/") ? API_URL.slice(0, -1) : API_URL;
    const pathname = path.startsWith("/") ? path.slice(1) : path;
    const url = new URL(
      `${BASE_URL}/${pathname.endsWith("/") ? pathname : `${pathname}/`}`
    );
    for (const [key, value] of Object.entries(searchParams)) {
      if (!value)
        continue;
      for (const v of [value].flat()) {
        url.searchParams.append(key, v.toString());
      }
    }
    return url.toString();
  }
  constructOptions(method, h, body, token, locale, responseType, signal) {
    const headers = {
      ...h || {},
      Accept: responseType === "blob" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "application/json"
      // Adjusted Accept header
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if (locale) {
      headers["Accept-Language"] = locale;
    }
    const options = { method, headers };
    if (body) {
      if (typeof body === "string") {
        options.body = body;
      } else {
        options.body = JSON.stringify(body);
        headers["Content-Type"] = "application/json";
      }
    }
    if (signal) {
      options.signal = signal;
    }
    options.credentials = "include";
    return options;
  }
  handleNetworkError() {
    const networkError = new ApiNetworkError("Network Error");
    if (this.onError)
      this.onError(networkError);
    throw networkError;
  }
  setOnError(handler) {
    this.onError = handler;
  }
  get(pathname, opts = {}) {
    return this.api(pathname, {
      ...opts,
      method: "GET",
      token: this.token,
      locale: this.locale
    });
  }
  post(pathname, opts = {}) {
    return this.api(pathname, {
      ...opts,
      method: "POST",
      token: this.token,
      locale: this.locale
    });
  }
  put(pathname, opts = {}) {
    return this.api(pathname, {
      ...opts,
      method: "PUT",
      token: this.token,
      locale: this.locale
    });
  }
  delete(pathname, opts = {}) {
    return this.api(pathname, {
      ...opts,
      method: "DELETE",
      token: this.token,
      locale: this.locale
    });
  }
  patch(pathname, opts = {}) {
    return this.api(pathname, {
      ...opts,
      method: "PATCH",
      token: this.token,
      locale: this.locale
    });
  }
};

// src/enums/index.ts
var MarketType = /* @__PURE__ */ ((MarketType2) => {
  MarketType2["ALL"] = "ALL";
  MarketType2["NEW"] = "NEW";
  MarketType2["USED"] = "USED";
  return MarketType2;
})(MarketType || {});
var BuildStatus = /* @__PURE__ */ ((BuildStatus2) => {
  BuildStatus2["ALL"] = "ALL";
  BuildStatus2["UNKNOWN"] = "UNKNOWN";
  BuildStatus2["PLANNING"] = "PLANNING";
  BuildStatus2["PRESALE"] = "PRESALE";
  BuildStatus2["CONSTRUCTION"] = "CONSTRUCTION";
  BuildStatus2["TAKEOVER"] = "TAKEOVER";
  return BuildStatus2;
})(BuildStatus || {});
var SaleStatus = /* @__PURE__ */ ((SaleStatus2) => {
  SaleStatus2["ALL"] = "ALL";
  SaleStatus2["FORSALE"] = "FORSALE";
  SaleStatus2["SOLD"] = "SOLD";
  SaleStatus2["WITHDRAWN"] = "WITHDRAWN";
  SaleStatus2["REMOVED"] = "REMOVED";
  return SaleStatus2;
})(SaleStatus || {});
var ConstructionStatus = /* @__PURE__ */ ((ConstructionStatus2) => {
  ConstructionStatus2["NOT_STARTED"] = "NOT_STARTED";
  ConstructionStatus2["STARTED"] = "STARTED";
  ConstructionStatus2["FINISHED"] = "FINISHED";
  ConstructionStatus2["UNKNOWN"] = "UNKNOWN";
  return ConstructionStatus2;
})(ConstructionStatus || {});
var PropertyType = /* @__PURE__ */ ((PropertyType2) => {
  PropertyType2["ALL"] = "ALL";
  PropertyType2["UNKNOWN"] = "UNKNOWN";
  PropertyType2["DETACHED"] = "DETACHED";
  PropertyType2["SEMIDETACHED"] = "SEMIDETACHED";
  PropertyType2["TOWNHOUSE"] = "TOWNHOUSE";
  PropertyType2["APARTMENT"] = "APARTMENT";
  PropertyType2["CABIN"] = "CABIN";
  PropertyType2["PLOT"] = "PLOT";
  PropertyType2["FARM"] = "FARM";
  PropertyType2["OFFICE"] = "OFFICE";
  PropertyType2["STORE"] = "STORE";
  PropertyType2["WAREHOUSE"] = "WAREHOUSE";
  PropertyType2["COTTAGE"] = "COTTAGE";
  PropertyType2["OTHER"] = "OTHER";
  return PropertyType2;
})(PropertyType || {});
var FloorRemoveType = /* @__PURE__ */ ((FloorRemoveType2) => {
  FloorRemoveType2["GROUND_FLOOR"] = "GROUND_FLOOR";
  FloorRemoveType2["TOP_FLOOR"] = "TOP_FLOOR";
  return FloorRemoveType2;
})(FloorRemoveType || {});
var OwnershipType = /* @__PURE__ */ ((OwnershipType2) => {
  OwnershipType2["ALL"] = "ALL";
  OwnershipType2["UNKNOWN"] = "UNKNOWN";
  OwnershipType2["OWNED"] = "OWNED";
  OwnershipType2["COOPERATIVE"] = "COOPERATIVE";
  OwnershipType2["STOCK"] = "STOCK";
  OwnershipType2["BOND"] = "BOND";
  OwnershipType2["OTHER"] = "OTHER";
  return OwnershipType2;
})(OwnershipType || {});
var UseType = /* @__PURE__ */ ((UseType2) => {
  UseType2["ALL"] = "ALL";
  UseType2["UNKNOWN"] = "UNKNOWN";
  UseType2["RESIDENTIAL"] = "RESIDENTIAL";
  UseType2["RECREATIONAL"] = "RECREATIONAL";
  UseType2["COMMERCIAL"] = "COMMERCIAL";
  UseType2["INFRASTRUCTURE"] = "INFRASTRUCTURE";
  UseType2["OTHER"] = "OTHER";
  return UseType2;
})(UseType || {});
var AreaType = /* @__PURE__ */ ((AreaType2) => {
  AreaType2["REGION"] = "REGION";
  AreaType2["COUNTY"] = "COUNTY";
  AreaType2["DISTRICT"] = "DISTRICT";
  AreaType2["SUBDISTRICT"] = "SUBDISTRICT";
  AreaType2["BOROUGH"] = "BOROUGH";
  AreaType2["METRO"] = "METRO";
  AreaType2["METROZONE"] = "METROZONE";
  AreaType2["MUNICIPALITY"] = "MUNICIPALITY";
  return AreaType2;
})(AreaType || {});
var DataType = /* @__PURE__ */ ((DataType2) => {
  DataType2["ALL"] = "ALL";
  DataType2["PRICE"] = "PRICE";
  DataType2["SQARE_METER_PRICE"] = "SQARE_METER_PRICE";
  DataType2["SIZE"] = "SIZE";
  DataType2["DAYSONMARKET"] = "DAYSONMARKET";
  return DataType2;
})(DataType || {});
var FilterDataset = /* @__PURE__ */ ((FilterDataset2) => {
  FilterDataset2["NEW_PROJECT"] = "NEW_PROJECT";
  FilterDataset2["USED_UNIT"] = "USED_UNIT";
  FilterDataset2["LEGACY_UNIT"] = "LEGACY_UNIT";
  FilterDataset2["RENTAL_UNIT"] = "RENTAL_UNIT";
  return FilterDataset2;
})(FilterDataset || {});

// src/main.ts
var PlotApi = class {
  client;
  auth;
  billing;
  feedback;
  geodata;
  profile;
  registration;
  subscriptionPlan;
  workspace;
  constructor(token, locale) {
    this.client = new ApiClient(token, locale);
    this.auth = new Auth(this.client);
    this.feedback = new Feedback(this.client);
    this.billing = new Billing(this.client);
    this.geodata = new Geodata(this.client);
    this.profile = new Profile(this.client);
    this.registration = new Registration(this.client);
    this.subscriptionPlan = new SubscriptionPlan(this.client);
    this.workspace = new Workspace(this.client);
  }
};
var main_default = PlotApi;
export {
  ApiClient,
  ApiError,
  ApiNetworkError,
  AreaType,
  Auth,
  Billing,
  BuildStatus,
  ConstructionStatus,
  DataType,
  Feedback,
  FilterDataset,
  FloorRemoveType,
  Geodata,
  MarketType,
  OwnershipType,
  PlotApi,
  Profile,
  PropertyType,
  Registration,
  SaleStatus,
  SubscriptionPlan,
  UseType,
  Workspace,
  main_default as default
};
