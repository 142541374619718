import * as Sentry from '@sentry/react';

import { beforeSend, settings as sentrySettings } from '@/lib/sentry';

export const initializeSentry = () => {
  if (!import.meta.env.VITE_SENTRY_DSN) return;

  const sentryTracesSampleRate = parseFloat(
    import.meta.env.VITE_SENTRY_SAMPLE_RATE || '0.0'
  );

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_RELEASE_VERSION,
    debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',
    autoSessionTracking: false,
    sendClientReports: Boolean(sentryTracesSampleRate),
    tracesSampleRate: sentryTracesSampleRate,
    beforeSend,
    ...sentrySettings,
  });
};
