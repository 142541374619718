import { createContext, useContext, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import PlotApi, {
  type AuthDto,
  type AuthRequestDto,
  type PasswordResetConfirmDto,
  type PasswordResetRequestDto,
  type SocialAuthDto,
  type TokenRefreshDto,
  type TokenVerifyDto,
} from '@plot/plot-api';

import * as analytics from '@/lib/analytics/';
import { useLocale } from '@/lib/contexts';

type Token = Pick<AuthDto, 'access' | 'refresh'>;

interface AuthContextType {
  token: Token | null;
  signin: (credentials: AuthRequestDto) => Promise<void>;
  signinWithGoogle: (data: SocialAuthDto) => Promise<void>;
  signinWithMicrosoft: (data: SocialAuthDto) => Promise<void>;
  signOut: () => Promise<void>;
  verifyToken: (data: TokenVerifyDto) => Promise<void>;
  refreshToken: (data: TokenRefreshDto) => Promise<void>;
  resetPasswordRequest: (data: PasswordResetRequestDto) => Promise<void>;
  resetPasswordConfirm: (data: PasswordResetConfirmDto) => Promise<void>;
  authenticated: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const queryClient = useQueryClient();
  const [token, setToken] = useLocalStorage<Token | null>('token', null);
  const navigate = useNavigate();
  const { locale } = useLocale();

  const plotApi = useMemo(
    () => new PlotApi(token?.access, locale),
    [token?.access, locale]
  );

  useEffect(() => {
    async function verifyToken() {
      if (!token?.access) return;

      try {
        const result = await plotApi.auth.verifyToken({ token: token?.access });

        console.log('AuthProvider:verifyToken:result', result);
      } catch (err) {
        console.log('AuthProvider:verifyToken:error', err);

        setToken(null);
        navigate('/signin');
      }
    }

    verifyToken().catch(() => true);
  }, []);

  async function signin(data: AuthRequestDto) {
    const result = await plotApi.auth.signin(data);

    console.log('AuthProvider:signin:result', result);

    setToken(result);
  }

  async function signinWithGoogle(data: SocialAuthDto) {
    const result = await plotApi.auth.signinWithGoogle(data);

    console.log('AuthProvider:signinWithGoogle:result', result);

    setToken(result);
  }

  async function signinWithMicrosoft(data: SocialAuthDto) {
    const result = await plotApi.auth.signinWithMicrosoft(data);

    console.log('AuthProvider:signinWithMicrosoft:result', result);

    setToken(result);
  }

  async function signOut() {
    await Promise.resolve();

    analytics.core.setUser(undefined);
    setToken(null);
    navigate('/signin');
    await queryClient.invalidateQueries();
  }

  async function verifyToken(data: TokenVerifyDto) {
    const result = await plotApi.auth.verifyToken(data);

    console.log('AuthProvider:verifyToken:result', result);
  }

  async function refreshToken(data: TokenRefreshDto) {
    const result = await plotApi.auth.refreshToken(data);

    console.log('AuthProvider:refreshToken:result', result);
  }

  async function resetPasswordRequest(data: PasswordResetRequestDto) {
    const result = await plotApi.auth.resetPasswordRequest(data);

    console.log('AuthProvider:resetPasswordRequest:result', result);
  }

  async function resetPasswordConfirm(data: PasswordResetConfirmDto) {
    const result = await plotApi.auth.resetPasswordConfirm(data);

    console.log('AuthProvider:resetPasswordConfirm:result', result);
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        signin,
        signinWithGoogle,
        signinWithMicrosoft,
        signOut,
        verifyToken,
        refreshToken,
        resetPasswordRequest,
        resetPasswordConfirm,
        authenticated: !!token?.access,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
