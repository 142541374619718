import { useQuery, type UseQueryOptions } from 'react-query';

import { type AreaReportDto } from '@plot/plot-api';

import { useApi } from '@/lib/api';
import { useAuth, useLocale } from '@/lib/contexts';

export type ReportQueryParams = {
  workspaceId?: string;
  reportId?: string;
};

export function useReport(
  { workspaceId, reportId }: ReportQueryParams = {},
  options: Omit<
    UseQueryOptions<AreaReportDto | undefined>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const { api } = useApi();
  const { authenticated, token } = useAuth();
  const { locale } = useLocale();

  return useQuery(
    [
      'area-report',
      workspaceId,
      reportId,
      authenticated,
      token?.access,
      locale,
    ],
    async () => {
      if (!authenticated) return undefined;

      if (!workspaceId) return undefined;
      if (!reportId) return undefined;

      return api.workspace.areaReport.get(workspaceId, reportId);
    },
    options
  );
}
