import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { WorkspaceDto } from '@plot/plot-api';

import { useWorkspace, useWorkspaces } from '@/lib/api';

interface WizardContextType {
  active: WorkspaceDto | undefined;
  prev: WorkspaceDto | undefined;
  setActiveId: (workspaceId?: string) => void;
}

const WizardContext = createContext<WizardContextType | undefined>(undefined);

type WizardProviderProps = {
  children: React.ReactNode;
};

export function WizardProvider({ children }: WizardProviderProps) {
  const [activeId, setActiveId] = useState<string | undefined>();
  const [prevId, setPrevId] = useState<string | undefined>();
  const params = useParams<{ workspace: string }>();

  const workspace = useWorkspace({
    workspaceId: params?.workspace ?? activeId,
  });
  const prev = useWorkspace({ workspaceId: prevId });

  useEffect(() => {
    if (workspace.data?.isSetupComplete) {
      setActiveId(undefined);
      setPrevId(workspace?.data?.id);
    }
  }, [workspace]);

  return (
    <WizardContext.Provider
      value={{ active: workspace?.data, prev: prev?.data, setActiveId }}
    >
      {children}
    </WizardContext.Provider>
  );
}

export function useWizard() {
  const context = useContext(WizardContext);

  if (context === undefined) {
    throw new Error('useWizard must be used within an WizardContext');
  }

  return context;
}
