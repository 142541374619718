import { useMemo, useState } from 'react';
import { animated, useTransition } from '@react-spring/web';
import { useOutlet } from 'react-router-dom';

import { useSection } from '@/lib/hooks';

function AnimatedOutlet() {
  const o = useOutlet();
  const [outlet] = useState(o);
  return outlet;
}

function PageTransition({ children }: { children: React.ReactNode }) {
  const section = useSection();

  const context = useMemo(() => {
    switch (section) {
      case '':
      case undefined:
      case 'workspaces':
        return 'root';

      default:
        return section;
    }
  }, [section]);

  const transition = useTransition(context, {
    initial: { opacity: 1 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transition(
    (style, item) =>
      item &&
      item !== 'root' && (
        <animated.div
          key={item}
          style={style}
          className="min-h-screen w-full bg-neutral-0 dark:bg-neutral-70"
        >
          {children}
        </animated.div>
      )
  );
}

export function PageLayout() {
  return (
    <PageTransition>
      <AnimatedOutlet />
    </PageTransition>
  );
}

export default PageLayout;
