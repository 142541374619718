import { useCallback, useEffect, useMemo } from 'react';
import { animated, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { MapProvider } from 'react-map-gl';
import {
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  type Location,
} from 'react-router-dom';
import { useDarkMode } from 'usehooks-ts';

import { Loader } from '@plot/ui';

import { useProfile } from '@/lib/api';
import { TransitionStateProvider, useAuth } from '@/lib/contexts';
import { useSection } from '@/lib/hooks';

import { Sidebar } from '@/components/sidebar';
import { WaitingList } from '@/components/waiting-list';
import { Workspace } from '@/components/workspace';

function PageTransition({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const section = useSection();

  const context = useMemo(() => {
    switch (section) {
      case '':
      case undefined:
      case 'workspaces':
        return 'root';

      case 'profile':
      case 'billing':
        return 'account';

      case 'settings':
      case 'members':
      case 'subscription':
      case 'invoices':
        return 'manage';

      default:
        return section;
    }
  }, [section]);

  const transition = useTransition(context, {
    initial: {
      // opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    from: {
      // opacity: 0,
      transform: (location.state as { back: boolean } | null)?.back
        ? 'translate3d(-100%, 0, 0)'
        : 'translate3d(100%, 0, 0)',
    },
    enter: {
      // opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    leave: {
      // opacity: 0,
      transform: (location.state as { back: boolean } | null)?.back
        ? 'translate3d(100%, 0, 0)'
        : 'translate3d(-100%, 0, 0)',
    },
  });

  return transition(
    (style, item, transitionState) =>
      item &&
      item !== 'root' && (
        <animated.div
          key={item}
          style={style}
          className="z-0 min-h-screen w-full bg-neutral-0 dark:bg-neutral-70"
        >
          <TransitionStateProvider transitionState={transitionState}>
            {children}
          </TransitionStateProvider>
        </animated.div>
      )
  );
}

export function WorkspaceLayout() {
  const { isDarkMode } = useDarkMode();
  const location = useLocation();
  const auth = useAuth();
  const profile = useProfile();

  const getKey = useCallback((location: Location) => location.key, []);

  useEffect(() => {
    if (!document) return;

    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    return () => {
      document.documentElement.classList.remove('dark');
    };
  }, [isDarkMode]);

  if (!auth.token) {
    return <Navigate to={`/signin`} state={{ from: location }} replace />;
  }

  if (isMobile) {
    return <Navigate to={`/not-supported`} />;
  }

  return (
    <MapProvider>
      <div className={clsx('pl-56')}>
        <Sidebar />
        <main id="main" className="relative min-h-screen">
          {profile.isFetched &&
            (profile.data?.isOnWaitingList ? (
              <WaitingList />
            ) : (
              <>
                <Workspace />

                <PageTransition>
                  <Outlet />
                </PageTransition>
              </>
            ))}
        </main>
      </div>
      {profile.isFetching && <Loader fullscreen backdrop />}
      <ScrollRestoration getKey={getKey} />
    </MapProvider>
  );
}

export default WorkspaceLayout;
