import { Alarm } from '@phosphor-icons/react';
import { useTranslations } from 'use-intl';

import { Anchor } from '@plot/ui';

export function WaitingList() {
  const t = useTranslations('WaitingList');

  return (
    <article className="flex min-h-screen w-full flex-col items-center justify-center text-center">
      <header className="flex flex-col items-center">
        <Alarm
          className="h-20 w-20 text-primary dark:text-primary-40"
          weight="thin"
        />
        <h1 className="hd-xl my-6">{t('heading')}</h1>
      </header>
      <p
        className="my-4"
        dangerouslySetInnerHTML={{ __html: t.raw('content') as string }}
      />
      <p className="my-4">
        {t.rich('footer', {
          mailToLink: (chunks) => (
            <Anchor variant="primary" href={`mailto:${chunks as string}`}>
              {chunks}
            </Anchor>
          ),
        })}
      </p>
    </article>
  );
}

export default WaitingList;
