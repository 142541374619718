import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { RegistrationProvider, useAuth } from '@/lib/contexts';

import { MobileLayout } from '../mobile';

export function AuthLayout() {
  const t = useTranslations('Auth.Layout');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (auth.token?.access) {
      const originalTarget = (location.state as { from?: Location } | undefined)
        ?.from?.pathname;

      navigate(originalTarget ?? '/', { replace: true });
      // navigate('/', { replace: true });
    }
  }, [navigate, auth.token?.access]);

  return (
    <RegistrationProvider>
      <MobileLayout>
        <div className="relative flex items-center justify-center px-8 pb-8 sm:p-8">
          <Outlet />
        </div>
      </MobileLayout>
    </RegistrationProvider>
  );
}

export default AuthLayout;
