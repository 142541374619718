import { useQuery, type UseQueryOptions } from 'react-query';

import { type WorkspaceDto } from '@plot/plot-api';

import { useApi } from '@/lib/api';
import { useAuth, useLocale } from '@/lib/contexts';

export type WorkspaceQueryParams = {
  workspaceId?: string;
};

export function useWorkspace(
  { workspaceId }: WorkspaceQueryParams = {},
  options: Omit<
    UseQueryOptions<WorkspaceDto | undefined>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const { api } = useApi();
  const { authenticated, token } = useAuth();
  const { locale } = useLocale();

  return useQuery(
    ['workspace', workspaceId, authenticated, token?.access, locale],
    async () => {
      if (!authenticated) return undefined;

      if (!workspaceId) return undefined;

      return api.workspace.get(workspaceId);
    },
    options
  );
}
