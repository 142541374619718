import { createContext, useContext, useState } from 'react';

import {
  type UserRegistrationSessionDto,
  type UserRegistrationSessionRequestDto,
  type VerificationCodeDto,
} from '@plot/plot-api';

import { useApi } from '@/lib/api';

type RegistrationSession = UserRegistrationSessionDto;

interface RegistrationContextType {
  session: RegistrationSession | null;
  register: (data: UserRegistrationSessionRequestDto) => Promise<void>;
  resendVerificationCode: () => Promise<void>;
  verifyAccount: (data: VerificationCodeDto) => Promise<void>;
}

const RegistrationContext = createContext<RegistrationContextType | undefined>(
  undefined
);

type RegistrationProviderProps = {
  children: React.ReactNode;
};

export function RegistrationProvider({ children }: RegistrationProviderProps) {
  const [session, setSession] = useState<RegistrationSession | null>(null);
  const { api } = useApi();

  async function register(data: UserRegistrationSessionRequestDto) {
    const result = await api.registration.create(data);

    console.log('RegistrationProvider:register:result', result);

    setSession(result);
  }

  async function resendVerificationCode() {
    if (!session?.id) return;

    const result = await api.registration.resendVerification(session.id);

    console.log('RegistrationProvider:resendVerificationCode:result', result);
  }

  async function verifyAccount(data: VerificationCodeDto) {
    if (!session?.id) return;

    const result = await api.registration.verifyAccount(session.id, data);

    console.log('RegistrationProvider:resendVerificationCode:result', result);
  }

  return (
    <RegistrationContext.Provider
      value={{ session, register, resendVerificationCode, verifyAccount }}
    >
      {children}
    </RegistrationContext.Provider>
  );
}

export function useRegistration() {
  const context = useContext(RegistrationContext);

  if (context === undefined) {
    throw new Error(
      'useRegistration must be used within an RegistrationProvider'
    );
  }

  return context;
}
