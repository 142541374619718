import { AreaReportDto } from '@plot/plot-api';

import { Any } from '@/lib/analytics/utils';

import {
  WorkspaceTracker,
  WorkspaceTrackerBoundData,
} from './WorkspaceTracker';

export declare interface AreaReportTrackerBoundData
  extends WorkspaceTrackerBoundData {
  areaReport: AreaReportDto;
}

export class AreaReportTracker<
  BoundData extends AreaReportTrackerBoundData = AreaReportTrackerBoundData
> extends WorkspaceTracker<BoundData> {
  track(eventName: string, eventProperties: object = {}) {
    super.track(eventName, {
      areaReportId: this.boundData.areaReport.id,
      ...eventProperties,
    });
  }

  areaReportViewed() {
    this.track('AreaReportViewed');
  }

  areaReportChartFilterChanged(
    chartName: string,
    filterName: string,
    filterVal: Any,
    activeFilters: string[]
  ) {
    this.track('AreaReportChartFilterChanged', {
      chartName,
      filterName,
      filterVal,
      activeFilters,
    });
  }
}
