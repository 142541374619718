import { Logo } from '@plot/ui';

interface MobileLayoutProps {
  children: React.ReactNode;
}

export const MobileLayout = ({ children }: MobileLayoutProps) => {
  return (
    <main id="main" className="min-h-screen sm:grid sm:grid-cols-2">
      <div
        style={{
          backgroundImage: "url('/MobileHeader.png')",
          backgroundSize: 'contain',
          backgroundRepeat: 'round',
        }}
        className="items dark h-[150px] sm:h-full sm:bg-neutral-70 sm:!bg-none"
      >
        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(101, 67, 219, 0.36) 0%, rgba(101, 67, 219, 0.00) 100%)',
          }}
          className="flex h-full w-full items-center justify-center sm:!bg-none"
        >
          <Logo className="block h-auto w-40 text-neutral-70 sm:w-44 sm:text-neutral-0" />
        </div>
      </div>
      {children}
    </main>
  );
};

export default MobileLayout;
