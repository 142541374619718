import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Dropdown } from '@plot/ui';

import { useWorkspaceTracker } from '@/lib/analytics/contexts';
import { useWorkspaces } from '@/lib/api';
import { useWorkspace } from '@/lib/contexts';
import { useSection } from '@/lib/hooks';

export function WorkspaceMenu() {
  const t = useTranslations('Sidebar.WorkspaceMenu');
  const section = useSection();
  const navigate = useNavigate();

  const { workspace: currentWorkspace } = useWorkspace();
  const allWorkspaces = useWorkspaces();

  const tracker = useWorkspaceTracker();

  function getDefaultItem() {
    if (section === 'create') {
      return {
        name: t('createWorkspace'),
        id: '',
      };
    }

    if (currentWorkspace) {
      return {
        name: currentWorkspace.name,
        id: currentWorkspace.id || '',
      };
    }

    return {
      name: t('defaultWorkspace'),
      id: '',
    };
  }

  if (!allWorkspaces.data?.count) return null;

  return (
    <Dropdown
      tooltipContent={t('tooltip')}
      defaultItem={getDefaultItem()}
      items={allWorkspaces.data.results.map((item) => {
        return { name: item.name, id: item.id || '' };
      })}
      onClickItem={(item) => {
        tracker?.workspaceSelected(item.id);
        navigate(`/workspaces/${String(item.id)}`);
      }}
      // button={{
      //   buttonLabel: t('addButton'),
      //   onClickButton: () => {
      //     navigate(`/workspaces/create`);
      //   },
      // }}
    />
  );
}

export default WorkspaceMenu;
