import React, { createContext, useContext } from 'react';
import { TransitionState } from '@react-spring/web';

const TransitionStateContext = createContext<
  TransitionState<string, { transform: string }> | undefined
>(undefined);

type TransitionStateProviderProps = {
  transitionState: TransitionState<string, { transform: string }>;
  children: React.ReactNode;
};

export function TransitionStateProvider({
  transitionState,
  children,
}: TransitionStateProviderProps) {
  return (
    <TransitionStateContext.Provider value={transitionState}>
      {children}
    </TransitionStateContext.Provider>
  );
}

export function useTransitionState() {
  const context = useContext(TransitionStateContext);

  if (context === undefined) {
    throw new Error(
      'useTransitionState must be used within an TransitionStateContext'
    );
  }

  return context;
}
