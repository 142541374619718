import { useLocation, useParams } from 'react-router-dom';

export function useSection() {
  const location = useLocation();
  const params = useParams<{ workspace: string }>();
  const section = location.pathname
    .split('/')
    .filter(Boolean)
    .filter((part) => part !== params.workspace)
    .pop();

  return section;
}

export default useSection;
