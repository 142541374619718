import { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';

import { ApiError, ApiNetworkError, PlotApi } from '@plot/plot-api';

import { useAuth, useLocale } from '@/lib/contexts';

import { ERROR_CODES } from '../constants/errors';

export const useApi = () => {
  const { token } = useAuth();
  const { locale } = useLocale();
  const auth = useAuth();

  // Error handling function for the API client
  const handleApiError = useCallback(
    async (error: ApiNetworkError | ApiError) => {
      if (error instanceof ApiNetworkError) {
        console.error(error);
        Sentry.captureException(error);
      }
      if (error instanceof ApiError) {
        if (import.meta.env.DEV) {
          console.log('PlotApi Err', error.data, error.options);
        }
        const errorCode = error.data.code;
        const invalidToken = errorCode === ERROR_CODES.InvalidToken;

        if (invalidToken) {
          await auth.signOut();
        }
      }
    },
    []
  );

  const plotApi = useMemo(() => {
    const api = new PlotApi(token?.access, locale);
    api.client.setOnError(async (error: ApiError | ApiNetworkError) => {
      await handleApiError(error);
    });
    return api;
  }, [token?.access, locale, handleApiError]);

  return { api: plotApi };
};
