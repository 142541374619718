import { ChatDots, Gear, MoonStars, SignOut, Sun } from '@phosphor-icons/react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { useDarkMode } from 'usehooks-ts';

import { Button, Divider, Logo, type ButtonProps } from '@plot/ui';

import { useProfile } from '@/lib/api';
import { Modal, useApp, useAuth, useWorkspace } from '@/lib/contexts';
import { useSection } from '@/lib/hooks';

import { ContextMenu } from '@/components/sidebar/context-menu';
import { WorkspaceMenu } from '@/components/sidebar/workspace-menu';

export function SidebarButton<C extends React.ElementType = 'button'>({
  className,
  isActive,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isPending,
  ...props
}: ButtonProps<C> & { isActive?: boolean; isPending?: boolean }) {
  return (
    <Button
      variant="none"
      stretch
      className={clsx(
        'p-2 hover:bg-neutral-10 hover:dark:bg-neutral-60',
        { 'bg-neutral-10 dark:bg-neutral-60': isActive },
        className
      )}
      {...(props as ButtonProps<C>)}
    />
  );
}

export type SidebarProps = {
  className?: string;
};

export function Sidebar({ className }: SidebarProps) {
  const t = useTranslations('Sidebar');
  const profile = useProfile();
  const auth = useAuth();
  const { workspace } = useWorkspace();
  const { isDarkMode, enable, disable } = useDarkMode();
  const {
    state: { modal },
    dispatch,
  } = useApp();
  const section = useSection();

  const DarkModeControlIcon = isDarkMode ? Sun : MoonStars;

  async function handleSignOut() {
    await auth.signOut();
  }

  function renderManageLink() {
    if (!workspace || workspace.isSuspended) return null;

    if (section === 'create') return null;

    return (
      <li className="my-1">
        <SidebarButton
          as={Link}
          noWrap={false}
          to={`/workspaces/${String(workspace.id)}/settings`}
        >
          <Gear className="mr-2 h-5 w-5" />
          {t('manageLink')}
        </SidebarButton>
      </li>
    );
  }

  return (
    <div
      className={clsx(
        'overflow-x-hidden',
        'fixed left-0 top-0 z-20',
        'h-screen w-56 flex-none',
        'flex flex-col',
        'elevation-neutral-xs',
        'bg-neutral-0 dark:bg-neutral-70',
        'border-r-1 border-neutral-20 p-4 dark:border-neutral-60'
      )}
    >
      <Button
        variant="none"
        stretch
        as={Link}
        to="/"
        state={{ back: true }}
        className="mb-8 mt-2"
      >
        <Logo />
      </Button>

      <WorkspaceMenu />

      <nav className="relative mt-8">
        <ContextMenu />
      </nav>

      <nav className="mt-auto">
        <ul className="flex flex-col">
          {renderManageLink()}
          <li className="my-1">
            <SidebarButton
              onClick={() =>
                dispatch({ type: 'openModal', payload: { id: Modal.FEEDBACK } })
              }
              isActive={modal?.id === Modal.FEEDBACK}
            >
              <ChatDots className="mr-2 h-5 w-5" />
              {t('feedbackLink')}
            </SidebarButton>
          </li>
          <li className="my-1">
            <SidebarButton onClick={isDarkMode ? disable : enable}>
              <DarkModeControlIcon className="mr-2 h-5 w-5" />
              {isDarkMode ? t('lightMode') : t('darkMode')}
            </SidebarButton>
          </li>
          <li className="my-1">
            <SidebarButton onClick={handleSignOut}>
              <SignOut className="mr-2 h-5 w-5" />
              {t('signOut')}
            </SidebarButton>
          </li>
        </ul>
      </nav>

      <Divider className="my-4" />

      <Button
        as={Link}
        to={`/profile`}
        variant="none"
        stretch
        className={clsx(
          'flex w-full items-center overflow-hidden p-1 hover:bg-neutral-10 hover:dark:bg-neutral-60'
        )}
      >
        <div className="mr-2 rounded-full bg-neutral-30 p-4 dark:bg-neutral-50"></div>
        <div
          className="cap-base w-full overflow-hidden text-ellipsis"
          title={profile.data?.fullName}
        >
          {profile.data?.fullName}
        </div>
      </Button>
    </div>
  );
}

export default Sidebar;
