import { createContext, useContext, useEffect } from 'react';
import enDate from 'date-fns/locale/en-GB';
import nbDate from 'date-fns/locale/nb';
import { registerLocale } from 'react-datepicker';
import { Twemoji } from 'react-emoji-render';
import { AbstractIntlMessages, DateTimeFormatOptions } from 'use-intl';
import { useLocalStorage } from 'usehooks-ts';
import { ZodErrorMap, defaultErrorMap } from 'zod';

import en from '@/messages/en.json';
import nb from '@/messages/nb.json';
import nbErrorMap from '@/messages/zod/nb';

export enum Locale {
  NB = 'nb',
  EN = 'en',
}

export const locales = [Locale.EN, Locale.NB];

export const dateLocales = {
  [Locale.EN]: enDate,
  [Locale.NB]: nbDate,
};

export const localeFlags = {
  [Locale.EN]: <Twemoji text="🇬🇧" />,
  [Locale.NB]: <Twemoji text="🇳🇴" />,
};

registerLocale('nb', nbDate);

export function getPreferredLocale() {
  const locale = navigator.language.split('-')[0];

  switch (locale) {
    case Locale.NB:
      return Locale.NB;

    default:
      return Locale.EN;
  }
}

export const getCountryDateFormat = (countryCode: string) => {
  let formatString = 'dd.MM.yyyy'; // Default format string
  try {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    } as DateTimeFormatOptions;
    const formatter = new Intl.DateTimeFormat(countryCode, options);
    const sampleDate = new Date(2023, 0, 1); // Use a sample date to generate the formatted string
    const formattedDate = formatter.format(sampleDate);

    // Extracting separator from the formatted date
    const separator = formattedDate.match(/[^\d]/)?.[0];

    if (separator) {
      formatString = formatString.replace(/\./g, separator);
    }

    return formatString;
  } catch (error) {
    console.error('Error retrieving date format:', error);
    return formatString;
  }
};

interface LocaleContextType {
  locale: Locale;
  dateFormat: string;
  messages: AbstractIntlMessages;
  errorMap: ZodErrorMap;
  setLocale: (locale: Locale) => void;
}

const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

type LocaleProviderProps = {
  children: React.ReactNode;
};

function getMessages(locale: Locale | null): AbstractIntlMessages {
  switch (locale) {
    case Locale.NB:
      return nb as AbstractIntlMessages;

    default:
      return en as AbstractIntlMessages;
  }
}

function getErrorMap(locale: Locale | null): ZodErrorMap {
  switch (locale) {
    case Locale.NB:
      return nbErrorMap;

    default:
      return defaultErrorMap;
  }
}

export function LocaleProvider({ children }: LocaleProviderProps) {
  const [locale, setLocale] = useLocalStorage<Locale>(
    'locale',
    getPreferredLocale()
  );

  const messages = getMessages(locale);

  const errorMap = getErrorMap(locale);

  const dateFormat = getCountryDateFormat(locale);

  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        dateFormat,
        messages,
        errorMap,
        setLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error('useLocale must be used within an LocaleProvider');
  }

  return context;
}
