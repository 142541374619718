import { useQuery, type UseQueryOptions } from 'react-query';

import { type ProfileDto } from '@plot/plot-api';

import { useApi } from '@/lib/api';
import { useAuth } from '@/lib/contexts';

export function useProfile(
  options: Omit<
    UseQueryOptions<ProfileDto | undefined>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const { api } = useApi();
  const { authenticated, token } = useAuth();

  return useQuery(
    ['profile', authenticated, token?.access],
    () => {
      if (!authenticated) return undefined;
      return api.profile.get();
    },
    options
  );
}
